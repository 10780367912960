import React, { useState, useEffect } from 'react'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import iconClose from '../../src/ic_modal_close.svg'

function ModalEditEmployeeBarcode(props) {

  const MySwal = withReactContent(Swal)

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  let handleClose = props.handleClose



  const [ employeeId, setEmployeeId ] = useState(props.employee_id)
  const [ employeeName, setEmployeeName ] = useState(props.employee_name)
  const [ barcodeStartPrev, setBarcodeStartPrev ] = useState(props.start)
  const [ barcodeEndPrev, setBarcodeEndPrev ] = useState(props.end)
  const [ barcodeStart, setBarcodeStart ] = useState(props.start)
  const [ barcodeEnd, setBarcodeEnd ] = useState(props.end)

  useEffect(() => {

  }, [])



  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  

  //api
  function callEditApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          member_id: employeeId,
          prev_barcode_start: barcodeStartPrev,
          prev_barcode_end: barcodeEndPrev,
          new_barcode_start: barcodeStart,
          new_barcode_end: barcodeEnd
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateMemberWithBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateEditApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function validateEditApi( data ){
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      handleClose(true)
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  } 


function validate(){
  if(  employeeName === ""){
    return false
  }
  return true
}

function save(){
  if(validate()){
    callEditApi()
  }
  else{

    MySwal.fire({
      icon: 'warning',
      title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
      confirmButtonText: "ตกลง",
    })
  }
}

  return (
    
        <div className={' fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={handleClose} src={iconClose}  alt="" className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        แก้ไขข้อมูล
                      </span>
                    </div>
                  </div>
                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    พนักงาน
                  </span>
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={employeeName}   className="w-full border rounded-md px-1 py-2 content-end" type="text" disabled />
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    Barcode เริ่มต้น
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={barcodeStart} onChange={(e)=>{ setBarcodeStart(e.target.value)}}  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    Barcode สิ้นสุด
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={barcodeEnd} onChange={(e)=>{ setBarcodeEnd(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>


                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalEditEmployeeBarcode;