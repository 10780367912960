import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import logoApp from '../../src/logo_app.png'
import pic from '../../src/IMAG4060.jpg'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'

function LoginPage() {
  
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)
  const [ username, setUsername ] = useState("")
  const [ password, setPassword] = useState("")

  function login(){
    navigate("/home")
  }

  useEffect(() => {

    localStorage.setItem('filter_orchid', JSON.stringify({
      page: '',
      filter: []
    }))
  }, [])


  //api
  function callAuthApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          username: username,
          password: password
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/login.php', requestOptions)
    .then(response => response.json())
    .then(data => validateAuthApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 

  function validateAuthApi( data ){


    if(data.data !== undefined ){
      localStorage.setItem(StoredLocalConstant.TOKEN, data.data.token)
      localStorage.setItem(StoredLocalConstant.GRANT, data.data.data.grant.id)
      localStorage.setItem(StoredLocalConstant.USER_NAME, `${data.data.data.name} ${data.data.data.surname} `)
      login()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function submit(){
    if(validate()){
      callAuthApi()
    }
    else{

      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }


  function validate(){
    if( username === "" || password === ""  ){
      return false
    }
    return true
  }

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sarabun w-full" >

        <div className="w-full flex flex-wrap bg-white pl-3 py-3">
          <img alt="" src={logoApp} className="h-8" />
          <span className="my-auto ml-5 font-bold">ระบบติดตามงานกล้วยไม้</span>
        </div> 

        <div className="mx-auto w-10/12 lg:w-8/12 xl:w-6/12  flex flex-wrap bg-white rounded-xl  my-12">
          <div className="w-full sm:w-8/12 flex flex-wrap pl-5 pb-12 pr-5">
            <div className="w-full flex flex-wrap mt-12">
              <img alt="" src={logoApp} className="h-12" />
            </div>
            <span className="w-full font-bold text-3xl mt-5">เข้าสู่ระบบ</span>
            <span className="w-full text-gray-500  text-lg  mt-2">ระบบติดตามงานกล้วยไม้</span>


            <span className="w-full font-bold text-lg mt-5">Username</span>
            <input value={username} onChange={(e)=>{ setUsername(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />

            <span className="w-full font-bold text-lg' mt-5">Password</span>
            <input value={password} onChange={(e)=>{ setPassword(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="password"  />
          
            <button onClick={submit} className={"w-full flex text-white bg-green-500 justify-center py-2 rounded-lg mt-5"} >เข้าสู่ระบบ</button>
          

          </div>

          <div className="w-4/12 hidden sm:flex flex-wrap">
            <img src={pic} className="w-full rounded-tr-xl rounded-br-xl" alt="" />
          </div>
        </div>


      </div>
  )
}

export default LoginPage;