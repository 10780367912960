import React, { useState, useEffect } from 'react'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import iconClose from '../../src/ic_modal_close.svg'
import DatePicker from 'react-date-picker';

function ModalEditLogRemove(props) {

  const MySwal = withReactContent(Swal)

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  let handleClose = props.handleClose

  const [ barcode, setBarcode ] = useState(props.default)


  const [ removeDate, setRemoveDate ] = useState("")
  const [ remark, setRemark ] = useState("")
  const [ reason, setReason ] = useState("")
  const [ itemReasonList, setItemReasonList ] = useState([])

  useEffect(() => {
    callReasonApi()
  }, [])



  
  function callReasonApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourcePlantRemoveTypes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateReasonApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });

  }

  function validateReasonApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }
    if(result.length > 0){
      setReason(result[0].id)
    }
    setItemReasonList(result)

    callGetApi()
  }

  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  

  //api
  function callGetApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          barcode: barcode
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getLogPlantRemoveDetailByBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  
  function validateGetApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)

      setRemoveDate(infos.remove_date)
      setReason(infos.plant_remove_type.id)
      setRemark(infos.remark)

    }
  } 
  function validateEditApi( data ){
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      // setCustomerCode("")
      // setCustomerName("")
      // setAddress("")
      // setTel("")
      // setEmail("")
      handleClose(true)
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  } 


function validate(){
  // if(  customerCode === "" || customerName === ""){
  //   return false
  // }
  return true
}

function save(){
  if(validate()){
    callUpdateApi( barcode )
    
  }
  else{

    MySwal.fire({
      icon: 'warning',
      title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
      confirmButtonText: "ตกลง",
    })
  }
}




  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }


  function toStringDate( date ){

    if(date !== "" && date !== null){

      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }



  

  function gotoDelete(  barcode ){

    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: '<div class="swal-content">คุณกำลังลบข้อมูล<br/>ต้องการดำเนินการต่อหรือไม่?</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef4444',
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#FFFFF',

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi(  barcode )
      }
    })
  }

  function callDeleteApi( barcode ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          barcode : barcode
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-remove', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteLog(barcode, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateDeleteLog( barcode, data ){
    handleClose(true)
  }


  function callUpdateApi( barcode ){
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          barcode : barcode,
          "remove_date" : (removeDate),
          "plant_remove_type_id" : reason,
          "remark" : remark
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-remove', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateLog(barcode, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateUpdateLog( barcode, data ){
    handleClose(true)
  }

  return (
    
        <div className={' fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
          
      
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={()=> {  handleClose(false) }} src={iconClose}  alt="" className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        แก้ไขข้อมูล
                      </span>
                    </div>
                  </div>


                  <div className="w-full   flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      Barcode
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input  value={ barcode } className="w-full border rounded-md px-1 py-2 content-end" type="text" disabled  />
                      </div>
                    </div> 

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      สาเหตุ
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select value={ reason } onChange={ (e)=>{ setReason(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                          {
                            itemReasonList.map(function(item, index){
                              return <option value={item.id}>{item.description}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      วันที่นำออก
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        {/* <input min={ prevDate } value={ importDate } onChange={ (e)=>{ setImportDate(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                        <DatePicker format="dd/MM/y"  value={ toDate(removeDate) } onChange={ (value)=>{ setRemoveDate(toStringDate(value)) } } className='w-full border rounded-md px-1 py-2 content-en' />
                      </div>
                    </div> 


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      Remark
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input  value={ remark } onChange={ (e) => { setRemark(e.target.value)} } className="w-full border rounded-md px-1 py-2 content-end" type="text"   />
                      </div>
                    </div> 



                  </div>




                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                  </div>
                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={ (e) => { gotoDelete( barcode ) }} className="w-full mt-3   border px-12 py-2 text-white border-red-500 rounded-lg bg-red-500 font-bold" >ลบรายการ</button>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalEditLogRemove;