import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import iconClose from '../../src/ic_modal_close.svg'
import iconMenuReceipt from '../../src/ic_menu_receipt.png'
import iconMenuImport from '../../src/ic_menu_import.png'
import iconMenuExport from '../../src/ic_menu_export.png'
import iconMenuRemove from '../../src/ic_menu_remove.png'
import iconMenuReport from '../../src/ic_menu_report.png'
import iconMenuSetting from '../../src/ic_menu_setting.png'
import iconMenuMember from '../../src/ic_menu_member.png'

function ModalMobileMenu(props) {

  const MySwal = withReactContent(Swal)
  const [ isShowMenuAdmin, setIsShowMenuAdmin ] = useState(false)

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  let handleClose = props.handleClose




  const navigate = useNavigate();
  const location = useLocation();

  function gotoHome(){
    navigate("/home")
  }

  function gotoImport(){
    navigate("/import")
  }

  function gotoRemove(){
    navigate("/remove")
  }

  function gotoExport(){
    navigate("/export")
  }

  function gotoBarcodeWithEmployee(){
    navigate("/barcode/employee")
  }

  function gotoCustomer(){
    navigate("/customer")
  }

  function gotoFood(){
    navigate("/food")
  }

  function gotoMainFamily(){
    navigate("/mainfamily")
  }
  
  function gotoEmployee(){
    navigate("/employee")
  }
 
  function gotoReportProduction(){
    navigate("/report/production")
  }
  function gotoReportStock(){
    navigate("/report/stock")
  }
  function gotoReportBottle(){
    navigate("/report/bottle")
  }
  function gotoReportRemove(){
    navigate("/report/remove")
  }
  function gotoReportFailPlant(){
    navigate("/report/fail-plant")
  }
  function gotoReportRemoveAll(){
    navigate("/report/remove-all")
  }

  

  function gotoWorkType(){
    navigate("/worktype")  
  }

  function gotoChangePassword(){
    navigate("/employee/password")
  }

  function gotoBarcode(){
    navigate("/barcode")
  }

  function isStateActive( path ){
    if( path === location.pathname){
      return " bg-gray-100 "
    }
    return ""
  }
  function logout(){
    localStorage.setItem(StoredLocalConstant.TOKEN, "")
    localStorage.setItem(StoredLocalConstant.USER_NAME, "")
    localStorage.setItem('filter_orchid', null)
    login()
  }

  function login(){
    navigate("/")
  }

  function checkAdminGrant() {
    const grant = localStorage.getItem(StoredLocalConstant.GRANT) 
    if(grant && grant !== null && grant !== '' && grant === '3'){
      setIsShowMenuAdmin(true)
    } else {
      setIsShowMenuAdmin(false)
    }
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    checkAdminGrant()
  }, [])
  
  return (
    
        <div className={' fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
     
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={handleClose} src={iconClose}  alt="" className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        Menu
                      </span>
                    </div>
                  </div>


                  <div onClick={gotoHome} className={"w-full flex lg:hidden  rounded-lg py-2 px-2 mt-6" +isStateActive("/home")}>
                    <img alt="" src={iconMenuReceipt} className=" my-auto" />
                    <span className="ml-2  my-auto">ใบงาน</span>
                  </div>
                  <div onClick={gotoBarcode} className={"w-full flex lg:hidden  rounded-lg py-2 px-2 mt-3" +isStateActive("/home")}>
                    <img alt="" src={iconMenuReport} className=" my-auto" />
                    <span className="ml-2  my-auto">ค้นหา Barcode</span>
                  </div>
                  <div onClick={gotoImport}  className={"w-full flex lg:hidden    rounded-lg py-2 px-2 mt-3" +isStateActive("/import")}>
                    <img alt="" src={iconMenuImport} className=" my-auto" />
                    <span className="ml-2">นำเข้า</span>
                  </div>
                  <div onClick={gotoRemove}  className={"w-full flex lg:hidden    rounded-lg py-2 px-2 mt-3" +isStateActive("/remove")}>
                    <img alt="" src={iconMenuRemove} className=" my-auto" />
                    <span className="ml-2">นำออก</span>
                  </div>
                  <div onClick={gotoExport} className={"w-full hidden    rounded-lg py-2 px-2 mt-3" +isStateActive("/export")}>
                    <img alt="" src={iconMenuExport} className=" my-auto" />
                    <span className="ml-2">ส่งมอบ</span>
                  </div>
                  <div className="w-full flex lg:hidden     rounded-lg py-2 px-2 mt-3">
                    <img alt="" src={iconMenuReport} className=" my-auto" />
                    <span className="ml-2">รายงาน</span>
                  </div>
                    <div onClick={gotoReportProduction} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/production")}>
                      <span className="ml-2">รายงาน การผลิต</span>
                    </div>
                    <div onClick={gotoReportStock} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/stock")}>
                      <span className="ml-2">รายงาน คงคลัง</span>
                    </div>
                    <div onClick={gotoReportBottle} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/bottle")}>
                      <span className="ml-2">รายงาน ขวด</span>
                    </div>
                    {/* <div onClick={gotoReportRemove} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/remove")}>
                      <span className="ml-2">รายงาน ราทั้งหมด</span>
                    </div> */}
                    <div onClick={gotoReportFailPlant} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/fail-plant")}>
                      <span className="ml-2">รายงาน เนื้อเยื่อกล้วยไม้เสียหาย</span>
                    </div>
                    <div onClick={gotoReportRemoveAll} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/remove-all")}>
                      <span className="ml-2">รายงาน การนำเนื้อเยื่อกล้วยไม้ออกจากสินค้าคงคลัง</span>
                    </div>
                  <div className="w-full flex  lg:hidden    rounded-lg py-2 px-2 mt-3">
                    <img alt="" src={iconMenuSetting} className=" my-auto" />
                    <span className="ml-2">จัดการข้อมูล</span>
                  </div>
                    <div onClick={gotoBarcodeWithEmployee}  className={"w-full flex lg:hidden    rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/barcode/employee")}>
                      <span className="ml-2">Barcode พนักงาน</span>
                    </div>
                    <div onClick={gotoCustomer}  className={"w-full flex lg:hidden    rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/customer")}>
                      <span className="ml-2">ลูกค้า</span>
                    </div>
                    <div  onClick={gotoFood}  className={"w-full flex lg:hidden    rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/food")}>
                      <span className="ml-2">อาหารวุ้น</span>
                    </div>
                    <div onClick={gotoMainFamily} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/mainfamily")}>
                      <span className="ml-2">สายพันธุ์หลัก</span>
                    </div>
                    <div onClick={gotoWorkType} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/worktype")}>
                      <span className="ml-2">ประเภทงาน</span>
                    </div>
                    {
                      isShowMenuAdmin ?
                  <div onClick={gotoEmployee} className={"w-full flex  lg:hidden   rounded-lg py-2 px-2 mt-1" +isStateActive("/employee")}>
                    <img alt="" src={iconMenuMember} className=" my-auto" />
                    <span className="ml-2 my-auto">พนักงาน</span>
                  </div>
                  :
                  null
                }

                  <div onClick={gotoChangePassword} className={"w-full flex  rounded-lg py-2 px-2 mt-1" +isStateActive("/employee/password")}>
                    <img alt="" src={iconMenuMember} className=" my-auto" />
                    <span className="ml-2 my-auto">เปลี่ยนรหัสผ่าน</span>
                  </div>



                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={(e)=>{ logout() }} className="w-full mt-3   border px-12 py-2 text-white border-red-500 rounded-lg bg-red-500 font-bold" >ออกจากระบบ</button>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalMobileMenu;