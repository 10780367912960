import React, { useState, useEffect } from 'react'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import iconClose from '../../src/ic_modal_close.svg'

function ModalFilter(props) {

  const MySwal = withReactContent(Swal)

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  let handleClose = props.handleClose


  const [ categoryList, setCategoryList ] = useState([])

  const [ employeeId, setEmployeeId ] = useState(props.employee_id)
  const [ employeeName, setEmployeeName ] = useState("")
  const [ employeeSurname, setEmployeeSurname ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ tel, setTel ] = useState("")
  const [ username, setUsername ] = useState("")
  const [ permission, setPermission ] = useState("")
  const [ block, setBlock ] = useState("0")


  useEffect(() => {
    callGetApi()
    callGetCategoryApi()
  }, [])



  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  

  //api
  function callGetApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: employeeId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMemberDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function callGetCategoryApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceGrants.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetCategoryApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function callEditApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: employeeId,
          name: employeeName,
          surname: employeeSurname,
          phone: tel,
          email: email,
          grant_id: permission
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateMember.php', requestOptions)
    .then(response => response.json())
    .then(data => validateEditApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function callResetPasswordApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: employeeId,
          name: employeeName,
          surname: employeeSurname,
          phone: tel,
          email: email,
          grant_id: permission
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateMemberPassword.php', requestOptions)
    .then(response => response.json())
    .then(data => validateResetPasswordApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function callBlockApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: employeeId,
          is_block: (block === "0")? "1" : "0",
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateMemberBlock.php', requestOptions)
    .then(response => response.json())
    .then(data => validateBlockApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function validateGetApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      setEmployeeName(infos.name)
      setEmployeeSurname(infos.surname)
      setUsername(infos.username)
      setTel(infos.phone)
      setEmail(infos.email)
      setPermission(infos.grant.id)
      setBlock(infos.is_block)
    }
  } 
  function validateGetCategoryApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      setCategoryList(infos)
      setPermission(1)
    }
  } 
  function validateResetPasswordApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      MySwal.fire({
        icon: 'success',
        title: <p>รหัสผ่านใหม่คือ {infos.password}</p>,
        confirmButtonText: "ตกลง",
      })
      handleClose(true)
    }
  } 
  function validateBlockApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      handleClose(true)
    }
  } 
  
  function validateEditApi( data ){
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      setEmployeeName("")
      setEmployeeSurname("")
      setUsername("")
      setTel("")
      setEmail("")
      handleClose(true)
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  } 


function validate(){
  if(  employeeName === ""){
    return false
  }
  return true
}

function save(){
  if(validate()){
    callEditApi()
    
  }
  else{

    MySwal.fire({
      icon: 'warning',
      title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
      confirmButtonText: "ตกลง",
    })
  }
}

  return (
    
        <div className={' fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={handleClose} src={iconClose}  alt="" className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        แก้ไขข้อมูล
                      </span>
                    </div>
                  </div>
                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    username
                  </span>
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={username} onChange={(e)=>{ setUsername(e.target.value)}}  className="w-full border rounded-md px-1 py-2 content-end" type="text" disabled />
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    ชื่อ
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={employeeName} onChange={(e)=>{ setEmployeeName(e.target.value)}}  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    นามสกุล
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={employeeSurname} onChange={(e)=>{ setEmployeeSurname(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    E-mail
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={email} onChange={(e)=>{ setEmail(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    เบอร์โทรศัพท์
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap">
                      <input  value={tel} onChange={(e)=>{ setTel(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                    ตำแหน่ง
                  </span>

                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select  value={permission} onChange={(e)=>{ setPermission(e.target.value)}}  className="w-full border rounded-md px-1 py-2 content-end">
                        {
                            categoryList.map(function(item, index){
                              return (
                                <option value={item.id}>{item.description}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                  </div>
                  <div className=" w-full flex flex-wrap mt-2">
                    <button onClick={callResetPasswordApi} className="w-full mt-3   border px-12 py-2 text-white border-yellow-500 rounded-lg bg-yellow-500 font-bold" >Reset รหัสผ่าน</button>
                  </div>
                  <div className=" w-full flex flex-wrap mt-2">
                    {
                      block === "0" ?

                      <button onClick={callBlockApi} className="w-full mt-3   border px-12 py-2 text-white border-red-500 rounded-lg bg-red-500 font-bold" >ระงับสิทธิ์การใช้งาน</button>
                      :
                      
                      <button onClick={callBlockApi} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เปิดสิทธิ์ใช้งาน</button>                      
                    }
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalFilter;