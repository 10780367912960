import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import iconHome from '../../src/ic_home.png'

import { formatDate } from '../utils/format-date-time'

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
function ReceiptDetailPage() {

    
  const MySwal = withReactContent(Swal)
  
  const navigate = useNavigate();
  const location = useLocation()

  const [ receiptId, setReceiptId ] = useState(location.state.id)
  const [ receiptName, setReceiptName ] = useState("")
  const [ receiptCode, setReceiptCode ] = useState("")
  const [ receiptCustomer, setReceiptCustomer ] = useState("")
  const [ receiptNumOrder, setReceiptNumOrder ] = useState("")
  const [ receiptCreateAt, setReceiptCreateAt ] = useState("")

  const [ totalImport, setTotalImport ] = useState("0")
  const [ totalRemove, setTotalRemove] = useState("0")
  const [ totalExport, setTotalExport] = useState("0")
  const [ totalResult, setTotalResult] = useState("0")
  

  function gotoImportDetail(){
    navigate("/receipt/import", {
      state :{
        id : receiptId
      }
    })
  }
  function gotoRemoveDetail(){
    navigate("/receipt/remove", {
      state :{
        id : receiptId
      }
    })
  }
  function gotoExportDetail(){
    navigate("/receipt/export", {
      state :{
        id : receiptId
      }
    })
  }
  function gotoHome(){
    navigate("/home", {
      state :{
        id : receiptId
      }
    })
  }

  function gotoEdit(){
    navigate("/receipt/edit", {
      state :{
        id : receiptId
      }
    })
  }
  

  useEffect(() => {
    window.scrollTo(0, 0)
    callGetApi()
  }, [])

  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }

  //api
  function callGetApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReceiptDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }


  function callCheckDeleteApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/checkDeleteReceipt.php', requestOptions)
    .then(response => response.json())
    .then(data => validateCheckDeleteApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateCheckDeleteApi( data ){
    if(data.data !== undefined ){
      let result = data.data;
      if(result.total_import > 0 || result.total_remove > 0) {
        Swal.fire({
          title: 'ยืนยันการลบข้อมูล',
          html: '<div class="swal-content">ใบงานมีข้อมูลการนำเข้า '+(result.total_import)+' รายการ และนำออก '+(result.total_remove)+' รายการ<br/>ต้องการดำเนินการลบต่อหรือไม่?</div>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ef4444',
          confirmButtonText: '<span class="">ตกลง</span>',
          cancelButtonText: 'ยกเลิก',
          cancelButtonColor: '#FFFFF',
    
        }).then((result) => {
          if (result.isConfirmed) {
            callDeleteApi()
          }
        })
      } else {
        callDeleteApi()
      }
    }
  } 


  function callDeleteApi( ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          receipt_id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/receipt', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }

  function callDeleteImportApi( ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          receipt_id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-import/receipt', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteImportApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }

  function callDeleteRemoveApi( ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          receipt_id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-remove/receipt', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteRemoveApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function validateGetApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      setReceiptName(infos.name)
      setReceiptCode(infos.code)
      setReceiptNumOrder(infos.num_order)
      setReceiptCustomer(`${infos.customer.name}`)
      setReceiptCreateAt(infos.create_at)

      setTotalImport(infos.total_import)
      setTotalRemove(infos.total_remove)
      setTotalExport(infos.total_export)
      setTotalResult(infos.total_result)
    }
  } 
  function validateDeleteApi( data ){
    if(data.data !== undefined ){
      if(data.data.code == 200 ){
        callDeleteImportApi()
      }
    }
  } 

  function validateDeleteImportApi( data ){
    if(data.code !== undefined ){
      callDeleteRemoveApi()
    }
  } 


  function validateDeleteRemoveApi( data ){
    if(data.code !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>ลบสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      gotoHome()
    }
  } 

  function remove(){

    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: '<div class="swal-content">คุณกำลังลบข้อมูล<br/>ต้องการดำเนินการต่อหรือไม่?</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef4444',
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#FFFFF',

    }).then((result) => {
      if (result.isConfirmed) {
        callCheckDeleteApi()
      }
    })
  }


  function edit(){
    gotoEdit()
  }
  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >

        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 

            <div className="w-full ">
              <span className="font-bold text-3xl">{ receiptName }</span>
            </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-11/12  flex flex-wrap  my-12">

                <div className="w-full   flex flex-wrap ">
                  <div className="w-full  flex flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      รายละเอียดกล้วยไม้
                    </span>            
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> รหัสพันธุ์ไม้:</span>
                      </div>
                      <div className="w-4/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> { receiptCode }</span>
                      </div>
                    </div>            
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> ชื่อพันธุ์ไม้:</span>
                      </div>
                      <div className="w-4/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> { receiptName }</span>
                      </div>
                    </div>            
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> ชื่อคู่ค้า:</span>
                      </div>
                      <div className="w-4/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> { receiptCustomer }</span>
                      </div>
                    </div>       
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> วันที่สร้าง:</span>
                      </div>
                      <div className="w-4/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> { formatDate(receiptCreateAt) }</span>
                      </div>
                    </div>
                    
                    <div className="w-full flex flex-wrap mt-3">
                        <div className='w-1/2 flex justify-start'>
                          <button  onClick={remove} className=" mt-3 border px-3 py-2 text-white border-red-500 rounded-lg bg-red-500 font-bold" >ลบใบงาน</button>  
                        </div>
                        <div className='w-1/2 flex justify-end'>
                          <button  onClick={edit} className="mr-4 mt-3 border px-3 py-2 text-white border-yellow-500 rounded-lg bg-yellow-500 font-bold" >แก้ไขข้อมูล</button>  
                        </div>
                    </div>

                  </div>




                </div>


                <div className="w-full flex flex-wrap  mt-3">
                  <div className="w-full  bg-white rounded-xl pl-5 py-5 pr-5">
                    
                          
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      ข้อมูลเพิ่มเติม
                    </span>            
                    {/* <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> จำนวนที่สั่ง:</span>
                      </div>
                      <div className="w-4/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> { (receiptNumOrder === '-1')? 'ไม่ระบุ' : receiptNumOrder }</span>
                      </div>
                    </div>             */}
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> จำนวนนำเข้าทั้งหมด:</span>
                      </div>
                      <div className="w-1/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm">  { totalImport }</span>
                      </div>
                      <div className="w-3/6 flex flex-wrap">
                        <button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoImportDetail} >รายละเอียด</button>
                      </div>
                    </div>            
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> จำนวนนำออกทั้งหมด:</span>
                      </div>
                      <div className="w-1/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm">  { totalRemove }</span>
                      </div>
                      <div className="w-3/6 flex flex-wrap">
                        <button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoRemoveDetail} >รายละเอียด</button>
                      </div>
                    </div>           
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> จำนวนส่งมอบ:</span>
                      </div>
                      <div className="w-1/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm">  { totalExport }</span>
                      </div>
                      <div className="w-3/6 flex flex-wrap">
                        <button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoExportDetail} >รายละเอียด</button>
                      </div>
                    </div>         
                    <div className="w-full flex flex-wrap mt-3">
                      <div className="w-2/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm"> จำนวนคงเหลือ:</span>
                      </div>
                      <div className="w-1/6 flex flex-wrap">
                        <span className="w-full flex mt-2 text-black text-sm">  { totalResult }</span>
                      </div>
                    </div>  
                  </div>
                </div>

              </div>
            </div>



          </div>  
        </div>  

      </div>
  )
}

export default ReceiptDetailPage;