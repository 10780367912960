import React, { useState } from 'react'

import iconClose from '../../src/ic_modal_close.svg'
import iconSearch  from '../../src/ic_search.png'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
function ModalFullSearch(props) {

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  const [ modalTypeId ] = useState(props.type_id)
  const [ word, setWord ] = useState("")
  const [ itemList, setItemList ] = useState([])

  let handleClose = props.handleClose
  let handleSelected = props.handleSelected

  const [ isMatchAllPlantCode, setIsMatchAllPlantCode ] = useState(false)

  function getTitle(){
    if(modalTypeId === 0){
      return " ชื่อพันธุ์ไม้"
    }
    else if(modalTypeId === 1){
      return " ชื่อคู่ค้า"
    }
    else if(modalTypeId === 2){
      return " สายพันธุ์หลัก"
    }
    else if(modalTypeId === 3){
      return " อาหารวุ้น"
    }
    else if(modalTypeId === 4){
      return " รหัสพันธุ์ไม้"
    }
    else if(modalTypeId === 5){
      return " ประเภทงาน"
    }
    else if(modalTypeId === 6){
      return " พนักงาน"
    }
    else if(modalTypeId === 7 || modalTypeId === 101){
      return " ประเภทงานหลัก"
    }
  }


  function search(){
    if(modalTypeId === 0){
      callSearchReceiptApi()
    }
    else if(modalTypeId === 1){
      callSearchCustomerApi()
    }
    else if(modalTypeId === 2){
      callSearchPlantMainFamilyApi()
    }
    else if(modalTypeId === 3){
      callSearchPlantFoodApi()
    }
    else if(modalTypeId === 4){
      callSearchReceiptCodeApi()
    }
    else if(modalTypeId === 5){
      callSearchWorkTypeApi()
    }
    else if(modalTypeId === 6){
      callSearchEmployeeApi()
    }
    else if(modalTypeId === 7){
      callSearchMainWorkApi()
    }
    else if(modalTypeId === 101){
      callSearchMainWorkApi()
    }
  }


  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  

  //api
  function callSearchReceiptApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchReceiptName.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchReceiptApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callSearchReceiptCodeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word,
          is_match_all: isMatchAllPlantCode
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchReceiptCodes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchReceiptCodeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callSearchCustomerApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchCustomers.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchCustomerApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callSearchPlantMainFamilyApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchPlantFamilyMains.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchPlantMainFamilyApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callSearchPlantFoodApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchFood.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchFoodApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callSearchWorkTypeApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchWorkType.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchWorkTypeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function callSearchEmployeeApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word,
          grant: 1 
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchEmployee.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchEmployeeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function callSearchMainWorkApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: word
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchWorkMainType.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchMainWorkApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateSearchReceiptApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: "",
          description: info.name
        })
      }
    }

    setItemList(result)
  } 
  function validateSearchReceiptCodeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.receipt_id,
          description: info.code
        })
      }
    }

    setItemList(result)
  } 
  
  function validateSearchCustomerApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.customer_id,
          description: info.name
        })
      }
    }

    setItemList(result)
  } 
  function validateSearchPlantMainFamilyApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }

    setItemList(result)
  }  
  function validateSearchFoodApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.food_id,
          description: info.description
        })
      }
    }

    setItemList(result)
  }  
  function validateSearchWorkTypeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }

    setItemList(result)
  } 
  function validateSearchEmployeeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.member_id,
          description: info.name+" "+info.surname
        })
      }
    }

    setItemList(result)
  } 
  function validateSearchMainWorkApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }

    setItemList(result)
  } 

  

  function enterSearch( e ){
    if(e.key === 'Enter'){
      search()
    }
  }

  return (
    
        <div className={' fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={handleClose} src={iconClose}  alt="" className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        ค้นหา {getTitle()}
                      </span>
                    </div>
                  </div>

                  <div className=" w-full flex flex-wrap mt-2">
                      <div className="flex-grow flex-wrap">
                        <input onKeyPress={(e)=> { enterSearch(e) }}  onChange={(e)=>{ setWord(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      
                      <div className="flex-none flex flex-wrap pl-3">
                        <button onClick={()=>{search()}} className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                  </div>
                  <div className={ ((modalTypeId === 4)? ' block ' : ' hidden ')+" w-full flex flex-wrap mt-2"}>
                    <input  checked={ (isMatchAllPlantCode)? 'checked' : ''} onChange={ (e)=>{ setIsMatchAllPlantCode( (!isMatchAllPlantCode) ) } } className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                  </div>

                  <div className=" w-full overflow-auto  flex-wrap mt-2 border h-80 rounded-lg">
                    {
                      itemList.map(function(item, index){
                        return (

                          <div className="w-full flex py-3 px-2 border-b">
                            <div className="flex-grow flex-wrap">
                              <span>{item.description}</span>
                            </div>
                            <div className="flex-none flex flex-wrap pl-3">
                              <button onClick={()=>{handleSelected( item.id, item.description)}} className="bg-black text-white px-3 py-1 text-sm rounded-md">เลือก</button>
                            </div>
                          </div>
                        )
                      })
                    }
                    
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalFullSearch;