import axios, { Method } from 'axios'

const getParamsQuery = (params = {}) => {
  if (params !== {}) {
    return Object.keys(params)
      .reduce((query, param) => `${query}${param}=${params[param]}&`, '?')
      .slice(0, -1)
  }

  return ''
}



function exportFileExcel(url, method, body = {}, filename) {
  let axiosPromise;
  const getUrl = url + getParamsQuery(body);
  axiosPromise = axios({
    responseType: 'blob',
    timeout: 1800000,
    method,
    url: getUrl,
    data: body,
  });

  return axiosPromise
  .then((response) => {
    console.log('response')
    console.log(response)
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  })
  .catch((error) => {
    console.log('error')
    console.log(error)
    throw error?.response?.data
  })
}


export  { exportFileExcel };