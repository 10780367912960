import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import { formatDate, formatDateExcel } from '../utils/format-date-time'

import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'
import { exportFileExcel } from '../utils/export-file'
import iconSearch  from '../../src/ic_search.png'

import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import ReactExport from "react-export-excel";
import ProgressWaiting from '../components/ProgressWaiting';
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function ReportRemoveAllPage() {
  
  const navigate = useNavigate();

  const [ tableList, setTableList ] = useState([])
  const [ filterList, setFilterList ] = useState([])

  const [ isShowProgress, setIsShowProgress ] = useState(false)
  let [ showMessage, setShowMessage ] = useState(false)
  let [ showMessageContent, setShowMessageContent ] = useState('')

  let [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  let [ showMessageFullSearchContent, setShowMessageFullSearchContent ] = useState('')
  

  useEffect(() => {
    window.scrollTo(0, 0)
    getDefaultFromStorage()
  }, [])


  function saveFilterToStorage( filterList ){
    let json = {
      page: "report_remove_all",
      filter: filterList
    }
    localStorage.setItem("filter_orchid", JSON.stringify(json));
  }
  function getDefaultFromStorage(){
    let json = localStorage.getItem("filter_orchid")
    if(json === null){
      let defaultFilter = getDefaultFilter()
      setFilterList(defaultFilter)
      callGetApi(defaultFilter, page, perPage)
    }
    else{

      let obj = JSON.parse(json)
      if(obj.page === "report_remove_all"){
        setFilterList(obj.filter)
        callGetApi(obj.filter, page, perPage)
      }
      else{
        let defaultFilter = getDefaultFilter()
        setFilterList(defaultFilter)
        callGetApi(defaultFilter, page, perPage)
      }
    }
  }

  
  


  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage() {
    setShowMessage(false)
  }



  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function submitModalMessageFullSearch( filter ){
    let tags = convertFilterToRequest(filter)
    initTagSearch( tags )
    saveFilterToStorage( tags )
    callGetApi( tags, page, perPage )

    hideModalMessage()


  }

  function initTagSearch( tags ){
    setFilterList([])
    setFilterList(tags)
  }


  function convertFilterToRequest(filter){

    let oldArray = new Array()
    oldArray.push({
      id: "plantName",
      value: filter.name
    })
    oldArray.push({
      id: "plantCode",
      value: filter.code
    })
    oldArray.push({
      id: "familyMain",
      value: filter.familyMain
    })
    oldArray.push({
      id: "workType",
      value: filter.workType
    })
    oldArray.push({
      id: "food",
      value: filter.food
    })
    oldArray.push({
      id: "customer",
      value: filter.customer
    })
    oldArray.push({
      id: "employee",
      value: filter.employee
    })
    oldArray.push({
      id: "mainTask",
      value: filter.mainTask
    })
    oldArray.push({
      id: "importStartDate",
      value: filter.importStartDate
    })
    oldArray.push({
      id: "importEndDate",
      value: filter.importEndDate
    })
    oldArray.push({
      id: "reason",
      value: filter.reason
    })
    return oldArray
  }

  function getDefaultFilter(  ){
 
    let oldArray = new Array()
    
    oldArray.push({
      id: "plantName",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "plantCode",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "familyMain",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "workType",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "food",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "customer",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "employee",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "mainTask",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "importEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "reason",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : true
      }
    })

    return oldArray
  }

  function getMonthYearDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    return   moment().add(-1, 'days').format("YYYY-MM-DD")
  } 
  function getDateDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    let d = date.getDate()
    return   ( date.getFullYear())+"-"+(( m < 10 ) ?  "0"+m : m )+"-"+(( d < 10 ) ?  "0"+d : d )
  } 


  const columns = [
    
    {
      name: 'วัน/เดือน/ปี ที่นำออก',
      selector: row => formatDate(row.remove_date),
      wrap : true,
      width: "120px"  
    },
    {
        name: 'วัน/เดือน/ปี ที่นำเข้า',
        selector: row => formatDate(row.import_date),
        wrap : true,
        width: "120px"  
    },
    {
        name: 'รหัสพันธุ์ไม้',
        selector: row => row.receipt_code,
        wrap : true,
        width: "120px"  
    },

      {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.receipt_name,
        wrap : true,
        width: "120px"  
    },

    {
        name: 'สายพันธุ์หลัก',
        selector: row => row.plant_family_main,
        wrap : true,
        width: "120px"  
    },
    {
      name: 'ชื่อคู่ค้า',
      selector: row => row.customer_name,
      wrap : true,
      width: "120px"  
    },
    {
        name: 'ชื่อ - นามสกุล พนักงาน',
        selector: row => `${row.member_name} ${row.member_surname} `,
        wrap : true,
        width: "120px"  
    },
    {
        name: 'ประเภทงานหลัก',
        selector: row => row.main_work_type,
        wrap : true,
        width: "120px"  
    },
    {
        name: 'ประเภทงาน',
        selector: row => row.work_type,
        wrap : true,
        width: "100px"  
    },
    {
        name: 'จำนวนนำออก',
        selector: row => parseInt(row.total),
        wrap : true,
        right: true,
        width: "80px"  
    },
    {
        name: 'ประเภทนำออก',
        selector: row => row.description,
        wrap : true,
        width: "120px"  
    },
    {
        name: 'ผู้ใช้งานที่ยิงออก',
        selector: row => `${row.create_member_name} ${row.create_member_surname} `,
        wrap : true,
        width: "120px"  
    },

    
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

//api
function callGetApi( filter , page, per_page){
  setIsShowProgress(true)
  const requestOptions = {
    headers: new Headers({
      'content-type': 'application/json'
    }),
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: per_page
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_NEXTJS + '/report/remove-all', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}

function validateGetApi( data ){
  setIsShowProgress(false)
  if(data !== undefined ){
    let infos = (data)
    setTableList(infos.data)
    setTotalRowAll(parseInt(infos.total))
  }
} 



function removeTag( index ){

  let oldArray = [...filterList]

  setFilterList([])
  for(let i =0; i < oldArray.length; i++){
    if(index === i){
      oldArray[i].value.data.description = ''
      oldArray[i].value.data.id = ''
      oldArray[i].value.is_match_all = false
      
    }
  }
  setFilterList(oldArray)
  saveFilterToStorage(oldArray)
  callGetApi( oldArray, page, perPage)

}

function getTags(){
  return filterList
}

const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}

const callApiExportExcel = async () => {
  setIsShowProgress(true)
  const response = await exportFileExcel(
    process.env.REACT_APP_HOST_NEXTJS + '/excel/report-remove-all', 
    'GET', 
    { 
        filter: JSON.stringify(filterList)
    },
    'รายงาน การนำเนื้อเยื่อกล้วยไม้ออกจากสินค้าคงคลัง.xlsx')
  setIsShowProgress(false)
  return response;
}
const [ page, setPage ] = useState(1)
const [ perPage, setPerPage ] = useState(10)
const [ totalRowAll, setTotalRowAll ] = useState(10)
const handlePageChange = page => {
  setPage(page);
  // call
  callGetApi(filterList,  page, perPage )
};

const handlePerRowsChange = async (newPerPage, page) => {
  setPage(page)
  setPerPage(newPerPage)
  callGetApi(filterList,  page, newPerPage )
  
};

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessage ? <ModalFilter page={"report-remove-all"} default={filterList} show={showMessage} handleClose={hideModalMessage} handleSubmit={submitModalMessageFullSearch}  handleFullSearch={showModalMessageFullSearch} title={"แจ้งเตือน"} message={showMessageContent} button={"ลองอีกครั้ง"}  ></ModalFilter> : null
        }
        {
          showMessageFullSearch ? <ModalFullSearch show={showMessage} handleClose={hideModalMessageFullSearch}  title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">รายงาน การนำเนื้อเยื่อกล้วยไม้ออกจากสินค้าคงคลัง</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                    <button  onClick={()=>{setShowMessage(true)}} className="ml-3 mt-3 lg:mt-0 border px-3 py-2 border-green-500 rounded-lg text-green-500 font-bold" >ค้นหาโดยละเอียด</button>
                  </div>
                </div>
              </div>
              
            

            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( item.value.data.description !== "" ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                          <button onClick={(e)=>{ removeTag(index) }} className="flex my-auto" ><span className="text-xs text-black font-bold ml-3">X</span></button>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

      
            </div>

            <div className="w-full  mt-5  ">
            <button 
            onClick={callApiExportExcel}
            className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>
            {/* <ExcelFile filename={"รายงาน การนำเนื้อเยื่อกล้วยไม้ออกจากสินค้าคงคลัง"} element={<button className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>}>
  
                <ExcelSheet data={tableList} name="Report">
                    <ExcelColumn label="วัน/เดือน/ปี ที่นาออก" value={(col) => formatDateExcel(formatDate(col.remove_date)) }/>
                    <ExcelColumn label="วัน/เดือน/ปี ที่นำเข้า" value={(col) => formatDateExcel(formatDate(col.import_date)) }/>
                    <ExcelColumn label="รหัสพันธุ์ไม้" value="receipt_code"/>
                    <ExcelColumn label="ชื่อพันธุ์ไม้" value="receipt_name"/>
                    <ExcelColumn label="สายพันธุ์หลัก" value="plant_family_main"/>
                    <ExcelColumn label="ชื่อคู่ค้า" value="customer_name"/>
                    <ExcelColumn label="ชื่อ-นามสกุล พนักงาน"  value={(col) => `${col.member_name} ${col.member_surname}` } />
                    <ExcelColumn label="ประเภทงานหลัก" value="main_work_type"/>
                    <ExcelColumn label="ประเภทงาน" value="work_type"/>
                    <ExcelColumn label="จำนวนนำออก" value={(col) => parseInt(col.total) }/>
                    <ExcelColumn label="ประเภทนำออก"   value={(col) => col.description }/>
                    <ExcelColumn label="ผู้ใช้งานที่ยิงออก"  value={(col) => `${col.create_name} ${col.create_surname}` } /> */}
                    {/* <ExcelColumn label="export" value="export"/> */}
                    {/* 
                    */}

                    
                {/* </ExcelSheet>
            </ExcelFile> */}
            </div>

            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
              />
            </div>
            {/* <div className="w-full flex justify-end  mt-5">
              <span className="rounded-l-lg px-3  py-2 border text-sm  bg-white">&lt;</span>
              <span className="rounded-sm px-3 py-2 border text-sm bg-white border-green-900">1</span>
              <span className="rounded-sm px-3 py-2 border text-sm bg-white">2</span>
              <span className="rounded-sm px-3  py-2 border text-sm  bg-white">3</span>
              <span className="rounded-sm px-3  py-2 border text-sm bg-white">4</span>
              <span className="rounded-sm px-3  py-2 border text-sm bg-white">5</span>
              <span className="rounded-r-lg px-3  py-2 border text-sm bg-white">&gt;</span>
            </div> */}
          </div>  
        </div>  
        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default ReportRemoveAllPage;