import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import iconHome from '../../src/ic_home.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'




function ChangePasswordPage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ oldPassword, setOldPassword ] = useState("")
  const [ newPassword, setNewPassword ] = useState("")
  const [ rePassword, setRePassword ] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }



  //api
  function callAddApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          old_password: oldPassword,
          new_password: newPassword,
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/changePassword.php', requestOptions)
    .then(response => response.json())
    .then(data => validateAddApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function validateAddApi( data ){
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      setRePassword("")
      setNewPassword("")
      setOldPassword("")
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  } 



  function submit(){
    if(validate()){
      if( newPassword !== rePassword ){
        MySwal.fire({
          icon: 'warning',
          title: <p>กรุณายืนยันรหัสผ่านใหม่ให้ตรงกัน</p>,
          confirmButtonText: "ตกลง",
        })
      }
      else{
        callAddApi()
      }
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function validate(){
    if( oldPassword === "" ||  newPassword === "" ||  rePassword === "" ){
      return false
    }
    return true
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
          <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 
            <div className="w-full ">
              <span className="font-bold text-3xl">เปลี่ยนรหัสผ่าน</span>
            </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-11/12  flex flex-wrap  my-12">

                <div className="w-full lg:w-6/6  flex flex-wrap ">
                  <div className="w-full  flex flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
                

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      รหัสผ่านเดิม
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={oldPassword} onChange={(e)=>{ setOldPassword(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="password"  />
                      </div>
                    </div>

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      รหัสผ่านใหม่
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={newPassword} onChange={(e)=>{ setNewPassword(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="password"  />
                      </div>
                    </div>

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      ยืนยันรหัสผ่านเดิม
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={rePassword} onChange={(e)=>{ setRePassword(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="password"  />
                      </div>
                    </div>

                    <button  onClick={submit} className="mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >ตกลง</button>

                  </div>



                </div>


       

              </div>
            </div>



          </div>  
        </div>  

      </div>
  )
}

export default ChangePasswordPage;