import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import { formatDate } from '../utils/format-date-time'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DataTable from 'react-data-table-component';
import iconHome from '../../src/ic_home.png'
import ProgressWaiting from '../components/ProgressWaiting';

function ReceiptExportDetail() {
  
  const navigate = useNavigate();
  const location = useLocation()

  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ receiptId, setReceiptId ] = useState(location.state.id)
  const [ tableList, setTableList ] = useState([])
  
  const columns = [
    {
      name: 'Barcode',
      selector: row => row.barcode,
      sortable: true,
    },
    {
        name: 'วันที่ส่งมอบ',
        selector: row => formatDate(row.remove_date),
        sortable: true,
    },
    {
        name: 'พนักงาน',
        selector: row => row.member_made.name,
        sortable: true,
    },

    
    // {
    //     name: '',
    //     cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoDetail} id={row.ID}>ลบ</button>
    // },
];


useEffect(() => {
  window.scrollTo(0, 0)
  callGetApi()
}, [])

function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}


  //api
  function callGetApi( ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: receiptId,
          type_id: 5
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getLogPlantRemoveByReceipt.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  function validateGetApi( data ){
    setIsShowProgress(false)
    if(data.data !== undefined ){
      let infos = (data.data)
      setTableList(infos)
    }
  } 

  function gotoDetail( id ){
    navigate("/receipt/detail", {
      state : {
        id : id
      }
    })
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >

        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
              <span className="my-auto ml-3 text-sm">/</span>
              <button onClick={ (e)=>{ gotoDetail(receiptId)} }><span className="my-auto ml-3 text-sm">รายละเอียด</span></button>
            </div> 

            <div className="w-full ">
              <span className="font-bold text-3xl">จำนวนส่งมอบ</span>
            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              />
            </div>
            {/* <div className="w-full flex justify-end  mt-5">
              <span className="rounded-l-lg px-3  py-2 border text-sm  bg-white">&lt;</span>
              <span className="rounded-sm px-3 py-2 border text-sm bg-white border-green-900">1</span>
              <span className="rounded-sm px-3 py-2 border text-sm bg-white">2</span>
              <span className="rounded-sm px-3  py-2 border text-sm  bg-white">3</span>
              <span className="rounded-sm px-3  py-2 border text-sm bg-white">4</span>
              <span className="rounded-sm px-3  py-2 border text-sm bg-white">5</span>
              <span className="rounded-r-lg px-3  py-2 border text-sm bg-white">&gt;</span>
            </div> */}
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default ReceiptExportDetail;