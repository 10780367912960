import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DataTable from 'react-data-table-component';
import iconHome from '../../src/ic_home.png'
import { formatDate } from '../utils/format-date-time'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ModalEditLogRemove from '../components/ModalEditLogRemove';

import DatePicker from 'react-date-picker';

import ReactExport from "react-export-excel";
import ProgressWaiting from '../components/ProgressWaiting';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function RemoveHistoryDetailPage() {
  
  const navigate = useNavigate();
  const location = useLocation()
  const MySwal = withReactContent(Swal)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ isShowMenuAdmin, setIsShowMenuAdmin ] = useState(false)

  const [ filterList, setFilterList ] = useState([])

  
  const [ filter, setFilter ] = useState(location.state.filter)
  const [ importDate, setImportDate ] = useState(location.state.import_date)
  const [ receiptCode, setReceiptCode ] = useState(location.state.receipt_code)
  const [ mainTaskId, setMainTaskId ] = useState(location.state.main_task)
  const [ workType, setWorkType ] = useState(location.state.work_type)
  const [ food, setFood ] = useState(location.state.food)
  const [ customer, setCustomer ] = useState(location.state.customer)
  const [ employee, setEmployee ] = useState(location.state.employee)


  const [ receiptId, setReceiptId ] = useState("")
  const [ removeDate, setRemoveDate ] = useState(location.state.filter.remove_date)
  const [ reason, setReason ] = useState("")
  const [ memberId, setMemberId ] = useState("")
  const [ barcode, setBarcode ] = useState("")
  const [ remark, setRemark ] = useState("")
  const [ itemReasonList, setItemReasonList ] = useState([])

  const [ tableList, setTableList ] = useState([])
  
  const columns = [
    {
      name: 'Barcode',
      selector: row => row.barcode,
      wrap : true
    },    
    {
      name: 'วัน/เดือน/ปี ที่นำเข้า',
      selector: row => formatDate(row.import_date),
      wrap : true
    },
    {
      name: 'วัน/เดือน/ปี ที่นำออก',
      selector: row => formatDate(row.remove_date),
      wrap : true
    },
    {
        name: 'รหัสใบงาน',
        selector: row => row.receipt_code,
        wrap : true
    },
    {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.receipt_name,
        wrap : true
    },
    // {
    //     name: 'สายพันธุ์',
    //     selector: row => ( row.plant_family_main !== null )? row.plant_family_main : "",
    //     sortable: true,
    // },
    {
        name: 'ประเภทงานหลัก',
        selector: row => row.main_work_type,
        wrap : true
    },
    {
        name: 'ประเภทงาน',
        selector: row => row.work_type,
        wrap : true
    },
    {
        name: 'อาหารวุ้น',
        selector: row => row.food,
        wrap : true
    },
    {
        name: 'สาเหตุ',
        selector: row => row.remove_type,
        wrap : true
    },
    {
        name: 'Remark',
        selector: row => row.remark,
        wrap : true
    },
    // {
    //     name: 'ชื่อคู่ค้า',
    //     selector: row => ( row.customer_name !== null )? row.customer_name : "",
    //     sortable: true,
    // },
    {
      name: 'ชื่อ-นามสกุลพนักงาน',
      selector: row => `${row.member_name} ${row.member_surname}`,
      wrap : true
    },
    {
      name: '',
      cell:(row)=> true ?  <><button onClick={(e)=>{ showModalMessageFullSearch(row.barcode) }} class="font-bold bg-yellow-500 text-white px-2 py-1 ml-1 rounded-md" >แก้ไข</button></> : null,
      wrap : true
    },
    
    // {
    //     name: '',
    //     cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoDetail} id={row.ID}>ลบ</button>
    // },
];
function checkAdminGrant() {
  const grant = localStorage.getItem(StoredLocalConstant.GRANT) 
  if(grant && grant !== null && grant !== '' && grant === '3'){
    setIsShowMenuAdmin(true)
  } else {
    setIsShowMenuAdmin(false)
  }
  
}


useEffect(() => {
  console.log('STORAGE :');
  console.log(location);
  window.scrollTo(0, 0)
  checkAdminGrant()
  initTagSearch()
  callReasonApi()
}, [])

function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

function initTagSearch(  ){


  let oldArray = new Array()
  
  setFilterList([])
  oldArray.push({
    value : {
      data : {
        id: "",
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.receipt_code
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.work_type_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.food
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.customer_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.member_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.main_work_type_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.import_date
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.import_date
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.time_per_day
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.remove_date
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.remove_date
      },
      is_match_all : true
    }
  })



  setFilterList(oldArray)
  callSearchReceiptDetail( oldArray )
}


  //api
  function callSearchReceiptDetail( filter ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          filter: JSON.stringify(filter)
        }
      )
    };
  
    console.log(filter)
  
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReportHistoryRemoveDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  
  function callGetApi( ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: ""
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getLogPlantImportByReceipt.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  function validateGetApi( data ){
    setIsShowProgress(false)
    if(data.data !== undefined ){
      let infos = (data.data)
      if(infos.length > 0) {
        setRemark((infos[0].remark))
        setReason(parseInt(infos[0].plant_remove_type_id))
      }
      setTableList(infos)
    }
  } 

  function gotoDetail( id ){
    navigate("/receipt/detail", {
      state : {
        id : id
      }
    })
  }


  function gotoDelete(  barcode ){

    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: '<div class="swal-content">คุณกำลังลบข้อมูล<br/>ต้องการดำเนินการต่อหรือไม่?</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef4444',
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#FFFFF',

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi(  barcode )
      }
    })
  }


  function callReasonApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourcePlantRemoveTypes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateReasonApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });

  }

  function validateReasonApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }
    if(result.length > 0){
      setReason(result[0].id)
    }
    setItemReasonList(result)
  } 

  function callDeleteApi( barcode ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          barcode : barcode
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/deleteLogRemove.php', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteLog(barcode, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateDeleteLog( barcode, data ){
    initTagSearch()
  }

  function getTags(){
    return filterList
  }


  const [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)
  const [ modelBarcode, setModelBarcode ] = useState(0)
  
  function showModalMessageFullSearch( barcode ) {
    setModelBarcode(barcode)
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch(isRefresh) {
    setShowMessageFullSearch(false)
    if(isRefresh) {
      callSearchReceiptDetail(filterList)
    }
  }
  function showFilterSearch( id ){

    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }
  
  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }

  function onChangeDate(value){
    setRemoveDate(toStringDate(value))
  }

  function toStringDate( date ){
    if(date !== "" && date !== null){
  
      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }

  function save(){
    if(validate()){
      callUpdateApi(  )
      
    }
    else{
  
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function validate(){
    if( removeDate === null || removeDate === ""  ){
      return false
    }
    return true
  }

  function submitNewBarcode(){
    if( barcode !== '') {
      callInsertApi();
    }
  }
  function getBarcodes( tables ){
    let items = []
    for( let i = 0; i < tables.length; i++){
      items.push(tables[i].barcode)
    }
    return items
  }

  function callUpdateApi() {
    
    const body = {
      token: getToken(),
      barcodes: getBarcodes( tableList ),
      "remove_date" : (removeDate),
      "plant_remove_type_id" : reason,
      "member_made" : memberId,
      "reciept_id" : "",
      "remark" : remark
    }
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-remove/all', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateLog(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateUpdateLog( data ){
    callSearchReceiptDetail( filterList )
  // navigate('/remove/history' , {replace: true })
  }



  function callInsertApi( ){
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          "remove_date" : (removeDate),
          "barcode" : barcode,
          "plant_remove_type_id" : reason,
          "member_made" : memberId,
          "reciept_id" : receiptCode,
          "remark" : remark,
          "time_per_day": filter.time_per_day
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-remove', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 

  function validateInsertApi( data ){

    if(data.data !== undefined ){
      setBarcode('')
      callSearchReceiptDetail( filterList )
    }
    else {
      
      MySwal.fire({
        icon: 'warning',
        title: <p>{ data.message }</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
          {
            showMessageFullSearch ? <ModalEditLogRemove default={modelBarcode} show={showMessageFullSearch} handleClose={hideModalMessageFullSearch} title={"แจ้งเตือน"} message={''} button={"ลองอีกครั้ง"}  ></ModalEditLogRemove> : null
          }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/remove")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าประวัตินำออก</span>
            </div> 

            <div className="w-full ">
              <span className="font-bold text-3xl">รายละเอียดจำนวนนำออกทั้งหมด</span>
            </div>


            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( (item.value.data.description) !== undefined && ( (item.value.data.description) !== "" && (item.value.data.description).trim() !== ""  )  ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

      
            </div>


            <div className="w-full  mt-5  ">
            <ExcelFile filename={"รายงาน นำออก"} element={<button className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>}>
  
                <ExcelSheet data={tableList} name="Report">
                    <ExcelColumn label="Barcode"  value="barcode" />
                    <ExcelColumn label="วัน/เดือน/ปี ที่นำเข้า" value="import_date"/>
                    <ExcelColumn label="วัน/เดือน/ปี ที่นำออก" value="remove_date"/>
                    <ExcelColumn label="รหัสใบงาน" value="receipt_code"/>
                    <ExcelColumn label="ชื่อพันธุ์ไม้" value="receipt_name"/>
                    <ExcelColumn label="ประเภทงานหลัก" value="main_work_type"/>
                    <ExcelColumn label="ประเภทงาน" value="work_type"/>
                    <ExcelColumn label="อาหารวุ้น" value="food"/>
                    <ExcelColumn label="สาเหตุ" value="remove_type"/>
                    <ExcelColumn label="Remark" value="remark" />
                    <ExcelColumn label="ชื่อ-นามสกุลพนักงาน" value={(col) => `${col.member_name} ${col.member_surname}` }/>
            
                </ExcelSheet>
            </ExcelFile>
            </div>


            <div className="w-full bg-gray-200  flex flex-wrap mt-5">
              <div className=" w-full lg:w-1/2 flex  mt-5 flex-wrap px-3">
                <div className="w-full flex flex-wrap bg-white py-6 px-6  rounded-lg">
                  <div className="w-full flex flex-wrap ">
                    <span className="font-bold text-lg">แก้ไขข้อมูลรายการทั้งหมด</span>
                  </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      วันที่นำออก
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        {/* <input value={ removeDate } onChange={ (e)=>{ setRemoveDate(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                        <DatePicker format="dd/MM/y"  value={ toDate(removeDate) }onChange={ (value)=>{ onChangeDate(value) }  } className='w-full border rounded-md px-1 py-2 content-en' />
                      </div>
                    </div>

                      <span className="w-full flex mt-2 text-black text-md text-center">
                        สาเหตุ
                      </span>
                      <div className=" w-full flex flex-wrap mt-1">
                        <div className=" w-full flex flex-wrap">
                          <select value={ reason } onChange={ (e)=>{ setReason(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                            {
                              itemReasonList.map(function(item, index){
                                return <option value={item.id}>{item.description}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>


                      <span className="w-full flex mt-2 text-black text-md text-center">
                        Remark
                      </span>
                      <div className=" w-full flex flex-wrap mt-1">
                        <div className=" w-full flex flex-wrap">
                          <input value={ remark } onChange={ (e)=>{ setRemark(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                        </div>
                      </div>  


                      <div className=" w-full flex flex-wrap mt-2">
                        <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                      </div>

                </div>
              </div>

              <div className=" w-full lg:w-1/2   mt-5 flex-wrap px-3">
                <div className="w-full  flex-wrap bg-white py-6 px-6  rounded-lg">
                  <div className="w-full  flex-wrap ">
                    <span className="font-bold text-lg">เพิ่มข้อมูล Barcode</span>
                  </div>
                  <span className="w-full   text-black text-md">
                    Barcode
                  </span>

                  <div className=" w-full  flex-wrap mt-1">
                    <div className=" w-full flex-wrap">
                      <input 
                      value={ barcode } 
                      onChange= { (e) => { setBarcode(e.target.value)} }
                      onKeyPress={(e)=>{ if(e.key === 'Enter') { submitNewBarcode()  }}}
                      className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className=" w-full flex-wrap">
                      <button onClick={submitNewBarcode}  
                      className={
                        ((barcode === '') ? ' bg-gray-200 border-gray-200 ' : ' bg-green-500  border-green-500 ' )+
                        "w-full mt-3 border px-3 py-2 text-white rounded-lg  font-bold"} >เพิ่มรายการใหม่</button>  
                    </div>
                  </div> 
                </div>
              </div>  
            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              />
            </div>
 
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default RemoveHistoryDetailPage;