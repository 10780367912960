
import Lottie from "lottie-react";
import animationData from '../../src/animation_500_loading.json'

const ProgressWaiting = () => {
  const style = {
    height: 100,
    width: 100,
  };
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    style:{style},
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  // const { View } = useLottie(defaultOptions);
  return (
    <div className={'  fixed z-50 inset-0 overflow-y-auto  '}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div className="flex items-end justify-center lg:max-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 bg-black bg-opacity-30 " aria-hidden="true"></div>

      <span className="hidden sm:inline-block align-middle h-screen " aria-hidden="true">&#8203;</span>
  
      <div className="inline-block h-[100px] rounded-lg p-2 text-left  bg-white shadow-lg  overflow-hidden transform transition-all  align-middle ">

           {/* { View } */}
          <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={style}
          />
          <span className=' text-sm w-full flex justify-center'>กรุณารอสักครู่</span>
      </div>
    </div>
</div>   
  )
}

export default ProgressWaiting