import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'
import iconHome from '../../src/ic_home.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import iconSearch  from '../../src/ic_search.png'

import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'
import ModalEditEmployeeBarcode from '../../src/components/ModalEditEmployeeBarcode'

import DataTable from 'react-data-table-component';
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import ProgressWaiting from '../components/ProgressWaiting';
function BarcodeWithEmployeePage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ isShowProgress, setIsShowProgress ] = useState(false)
  const [ itemEmployeeList, setItemEmployeeList ] = useState([])
  const [ itemList, setItemList ] = useState([])

  const [ memberId, setMemberId ] = useState("")
  const [ memberName, setMemberName ] = useState("")
  
  const [ barcodeStart, setBarcodeStart ] = useState("")
  const [ barcodeEnd, setBarcodeEnd ] = useState("")

  let [ showMessage, setShowMessage ] = useState(false)
  let [ showMessageContent, setShowMessageContent ] = useState('')

  let [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  let [ showMessageFullSearchContent, setShowMessageFullSearchContent ] = useState('')

  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)

  let [ showMessageEdit, setShowMessageEdit ] = useState(false)
  const [ memberEditId, setMemberEditId ] = useState("")
  const [ memberEditName, setMemberEditName ] = useState("")
  const [ barcodeStartEdit, setBarcodeStartEdit ] = useState("")
  const [ barcodeEndEdit, setBarcodeEndEdit ] = useState("")

  const [ wordingSearch, setWordingSearch ] = useState("")
  
  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage() {
    setShowMessage(false)
  }

  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }


  function showModalMessageEdit() {
    setShowMessageEdit(true)
  }
  function hideModalMessageEdit() {
    setShowMessageEdit(false)
    callGetBarcodeApi()
  }


  function showFilterSearch( id ){
    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }

  function handlerSearchReceipt(id, description){

    if(modalFullSearchId === 6){
      setMemberId(id)
      setMemberName(description)
    }
    
    hideModalMessageFullSearch()
  }

  function gotoEdit( memberId, memberName, startBarcode, endBarcode ){
    setMemberEditId(memberId)
    setMemberEditName(memberName)
    setBarcodeStartEdit(startBarcode)
    setBarcodeEndEdit(endBarcode)

    showModalMessageEdit()
  }

  function gotoDelete( memberId, startBarcode, endBarcode ){
    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: '<div class="swal-content">คุณกำลังลบข้อมูล<br/>ต้องการดำเนินการต่อหรือไม่?</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef4444',
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#FFFFF',

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi( memberId, startBarcode, endBarcode )
      }
    })
  }


  const columns = [
    {
        name: 'ชื่อพนักงาน',
        selector: row => row.member,
    },
    {
        name: 'Barcode เริ่ม',
        selector: row => row.start,
    },
    {
        name: 'Barcode สิ้นสุด',
        selector: row => row.end,
    },
    {
        name: '',
        selector: row => <div><button  class="bg-yellow-500 mr-3 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoEdit(row.member_id, row.member, row.start, row.end)}} id={row.customer_id}>แก้ไข Barcode</button><button  class="bg-red-500 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoDelete(row.member_id, row.start, row.end)}} id={row.customer_id}>ลบ Barcode</button></div>
    },
];


  useEffect(() => {
    window.scrollTo(0, 0)
    callSearchEmployeeApi()
    callGetBarcodeApi()
  }, [])

  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
 
   //api
   function callSearchEmployeeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMembers.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchEmployeeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callGetBarcodeApi( ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMemberWithBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetBarcodeApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
  } 
  function callInsertBarcodeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          member_id: memberId,
          barcode_start: barcodeStart,
          barcode_end: barcodeEnd
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/insertMemberWithBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertBarcodeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callDeleteApi( memberId,  startBarcode, endBarcode  ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          member_id: memberId,
          barcode_start: startBarcode,
          barcode_end: endBarcode
        }
      )
    };
    fetch('http:///localhost:3000/member-with-barcode', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteBarcodeApi(data, memberId, startBarcode, endBarcode ), (error) => {
      if (error) {
        // handle error here
      }
    });
  }

  function callDeleteLogImportApi( memberId,  startBarcode, endBarcode  ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          member_id: memberId,
          barcode_start: startBarcode,
          barcode_end: endBarcode
        }
      )
    };
    fetch('http:///localhost:3000/log-plant-import/range-barcode', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteImportApi(data, memberId, startBarcode, endBarcode ), (error) => {
      if (error) {
        // handle error here
      }
    });
  }

  function callDeleteLogRemoveApi( memberId,  startBarcode, endBarcode  ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          member_id: memberId,
          barcode_start: startBarcode,
          barcode_end: endBarcode
        }
      )
    };
    fetch('http:///localhost:3000/log-plant-remove/range-barcode', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteRemoveApi(data, memberId, startBarcode, endBarcode ), (error) => {
      if (error) {
        // handle error here
      }
    });
  }


  function searchKeywordByCode(){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: wordingSearch
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchMemberWithBarcodeByCode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetBarcodeApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  function validateSearchEmployeeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.member_id,
          description: `${info.name} ${info.surname}`
        })
      }
    }
    if(result.length > 0){
      setMemberId(result[0].id)
    }
    setItemEmployeeList(result)
  } 
  function validateGetBarcodeApi( data ){
    setIsShowProgress(false)
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          member_id: info.member.member_id,
          member: `${info.member.name} ${info.member.surname}`,
          start: info.barcode_start,
          end: info.barcode_end
        })
      }
    }

    setItemList(result)
  } 
  function validateInsertBarcodeApi( data ){
    if(data.data !== undefined ){

      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })

      setMemberId("")
      setMemberName("")
      setBarcodeStart("")
      setBarcodeEnd("")

      callGetBarcodeApi()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }
  function validateDeleteBarcodeApi( data, memberId, startBarcode, endBarcode  ){
    if(data.code == 200 ){

     callDeleteLogImportApi(memberId, startBarcode, endBarcode )
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }
  function validateDeleteImportApi( data, memberId, startBarcode, endBarcode  ){
    if(data.code == 200 ){
      callDeleteLogRemoveApi(memberId, startBarcode, endBarcode )
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }
  function validateDeleteRemoveApi( data, memberId, startBarcode, endBarcode  ){
    if(data.code == 200 ){
      MySwal.fire({
        icon: 'success',
        title: <p>ลบสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })

      setMemberId("")
      setBarcodeStart("")
      setBarcodeEnd("")

      callGetBarcodeApi()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }



  function validate(){
    if( memberId === "" || barcodeStart === "" || barcodeEnd === ""){
      return false
    }
    return true
  }

  function submit(){
    if(validate()){
      callInsertBarcodeApi()
    }
    else{

      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function enterSearch( e ){
    if(e.key === 'Enter'){
      callApi()
    }
  }

  function callApi() {
    if(wordingSearch !== ""){
      searchKeywordByCode()
    }
    else{
      callGetBarcodeApi()
    }
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }
  

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessage ? <ModalFilter show={showMessage} handleClose={hideModalMessage}  handleFullSearch={showModalMessageFullSearch} title={"แจ้งเตือน"} message={showMessageContent} button={"ลองอีกครั้ง"}  ></ModalFilter> : null
        }
        {
          showMessageFullSearch ? <ModalFullSearch show={showMessageFullSearch}  type_id={modalFullSearchId}  handleClose={hideModalMessageFullSearch} handleSelected={handlerSearchReceipt} title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"}  ></ModalFullSearch> : null
        }
        {
          showMessageEdit ? <ModalEditEmployeeBarcode show={showMessageEdit} handleClose={hideModalMessageEdit} title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"} employee_id={memberEditId} employee_name={memberEditName} start={barcodeStartEdit} end={barcodeEndEdit}  ></ModalEditEmployeeBarcode> : null
        }


        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
          <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 
            <div className="w-full ">
              <span className="font-bold text-3xl">เพิ่มรายการ Barcode</span>
            </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-12/12  flex flex-wrap  my-12">

                <div className="w-full lg:w-6/6  flex flex-wrap ">
                  <div className="w-full  flex flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
                

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      พนักงาน
                    </span>
                    {/* <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select onChange={(e)=>{ setMemberId(e.target.value) }}  className="w-full border rounded-md px-1 py-2 content-end">
                          {
                            itemEmployeeList.map(function(item, index){
                              return <option value={item.id}>{item.description}</option>
                            })
                          }
                        </select>
                      </div>
                    </div> */}
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className="flex-grow flex-wrap">
                        <input value={ memberName } onChange={ (e)=>{ setMemberId(e.target.value) } } disabled  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      <div className="flex-none flex flex-wrap pl-3">
                        <button onClick={(e)=>{showFilterSearch(6)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                    </div>

  
                    <span className="w-full flex mt-2 text-black text-md text-center">
                      Barcode เริ่มต้น
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={barcodeStart} onChange={ (e)=>{setBarcodeStart(e.target.value) } } className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                    </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      Barcode สิ้นสุด
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={barcodeEnd} onChange={ (e)=>{setBarcodeEnd(e.target.value) } } className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                    </div>


                    <button onClick={submit} className="mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เพิ่ม</button>

                  </div>



                </div>


                <div className="w-full flex flex-wrap  mt-3 ">
                  <div className="w-full  bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      รายการ Barcode
                    </span>

                    <div className="w-full flex flex-wrap my-5">
                      <div className="w-full flex  flex-wrap">

                        <div className="flex-warp flex  ">
                          <div className=" flex-wrap ">
                            <input onKeyPress={enterSearch} value={wordingSearch} onChange={(e)=>{ setWordingSearch(e.target.value) }} placeholder={"ค้นหาด้วย Barcode"} className="w-72 border rounded-md px-1 py-2 content-end" type="text"  />
                          </div>
                          <div className="  ">
                            <button onClick={callApi}  className="w-content ml-4 border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >ค้นหา</button>  
                          </div>
                        </div>

                      </div>
                    </div>

                    <div  className="w-full">
                    <DataTable
                      columns={columns}
                      data={itemList}
                      pagination
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>  
        </div>  


        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default BarcodeWithEmployeePage;