import React, { useState , useEffect} from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'
import iconHome from '../../src/ic_home.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DataTable from 'react-data-table-component';
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DatePicker from 'react-date-picker';
function ExportPage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ itemEmployeeList, setItemEmployeeList ] = useState([])
  const [ itemResultList, setItemResultList ] = useState([])

  const [ exportDate, setExportDate ] = useState("")
  const [ memberId, setMemberId ] = useState("")
  const [ barcode, setBarcode ] = useState("")


 


  useEffect(() => {
    window.scrollTo(0, 0)
    callSearchEmployeeApi()
  }, [])

  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }

  //api
  function callSearchEmployeeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMembers.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchEmployeeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 

  function callInsertApi(){
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          "export_date" : exportDate,
          "barcode" : barcode,
          "member_made" : memberId,
          "reciept_id" : ""
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/insertLogPlantExport.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateSearchEmployeeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.member_id,
          description: `${info.name} ${info.surname}`
        })
      }
    }
    if(result.length > 0){
      setMemberId(result[0].id)
    }
    setItemEmployeeList(result)
  }
  function validateInsertApi( data ){
    let result = []
 
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      result.push({
          export_date : exportDate,
          barcode : barcode,
          member_made :  getMemberDescription(memberId),
          status : ""
        })
      
      setBarcode("")
    }
    else {
      result.push({
          export_date : exportDate,
          barcode : barcode,
          member_made :  getMemberDescription(memberId),
          status : data.message
        })
      
      MySwal.fire({
        icon: 'warning',
        title: <p>{ data.message }</p>,
        confirmButtonText: "ตกลง",
      })
    }
    for(let i = 0; i < itemResultList.length; i++){
      result.push(itemResultList[i])
    }
    setItemResultList(result)
  }

  function getMemberDescription( id ){
    for(let i = 0; i < itemEmployeeList.length; i++){
      if(itemEmployeeList[i].id === id)
      return itemEmployeeList[i].description
    }
    return ""
  }
  function submit(){
    if(validate()){
      callInsertApi()
    }
    else{

      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  
  }

  function validate(){
    if( barcode === "" ||   exportDate === ""  ){
      return false
    }
    return true
  }



  const columns = [
    {
        name: 'Barcode',
        selector: row => row.barcode,
        wrap : true
    },
    {
        name: 'วันที่ส่งมอบ',
        selector: row => row.export_date,
    },
    {
      name: 'พนักงาน',
      selector: row => row.member_made,
    },
    {
      name: 'สถานะ',
      cell:(row)=>(row.status === "")?<span  class="font-bold text-green-500" >สำเร็จ</span>  : <span  class="font-bold text-red-500" >{ row.status }</span>,
      wrap : true
    },
];


function toDate( dateStr ){
  if(dateStr !== "" && dateStr !== null){
    var date = new Date(dateStr)
    return date
  }
  return null
}

function toStringDate( date ){
  if(date !== "" && date !== null){

    const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
    return str
  }
  return null
}

const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >

        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 
            <div className="w-full ">
              <span className="font-bold text-3xl">ส่งมอบ</span>
            </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-11/12  flex flex-wrap  my-12">

                <div className="w-full lg:w-2/6  flex flex-wrap ">
                  <div className="w-full  flex flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
    
                    <span className="w-full flex mt-2 text-black text-md text-center">
                      วันที่ส่งมอบ
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        {/* <input  value={ exportDate } onChange={ (e)=>{ setExportDate(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                        <DatePicker format="dd/MM/y"  value={ toDate(exportDate) } onChange={ (value)=>{ setExportDate(toStringDate(value)) } } className='w-full border rounded-md px-1 py-2 content-en' />
                    
                      </div>
                    </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      พนักงาน
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select value={ memberId } onChange={ (e)=>{ setMemberId(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                          {
                            itemEmployeeList.map(function(item, index){
                              return <option value={item.id}>{item.description}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>



                  <div className="w-full   flex-wrap bg-white rounded-xl pl-5 py-3 pr-5 mt-3">
                  <span className="w-full flex mt-2 text-black text-md text-center">
                      Barcode
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={ barcode } onChange={ (e)=>{ setBarcode(e.target.value) } } onKeyDown={(e)=>{ if(e.key === 'Enter') { submit()  }}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                    </div>  
                    <div className="w-full flex flex-wrap">
                      <button onClick={submit}  className="w-full mt-3 border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เพิ่มรายการใหม่</button>  
                    </div>   
                  </div>



                </div>


                <div className="w-full lg:w-4/6 flex flex-wrap pl-0 lg:pl-3 mt-3 lg:mt-0">
                  <div className="w-full  bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      รายการส่งมอบ
                    </span>

                    <div  className="w-full">
                      <DataTable
                        columns={columns}
                        data={itemResultList}
                        />
                          
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>  
        </div>  

      </div>
  )
}

export default ExportPage;