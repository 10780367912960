import React from 'react';
import  { useNavigate } from 'react-router-dom'

import iconHome from '../../src/ic_home.png'

function SubHeader() {
  
  const navigate = useNavigate();

  function login(){
    navigate("/home")
  }


  return (
    <div className="w-full flex flex-wrap pl-12 py-5">
      <img src={iconHome} alt="" className="h-4 my-auto " />
      <span className="my-auto ml-3 text-sm">/</span>
      <span className="my-auto ml-3 text-sm">เพิ่มใบงาน</span>
    </div> 

  )
}

export default SubHeader;