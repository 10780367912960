import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'
import iconHome from '../../src/ic_home.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import ModalEditWorkType from '../../src/components/ModalEditWorkType'

import DataTable from 'react-data-table-component';

import ProgressWaiting from '../components/ProgressWaiting';


function WorkTypePage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ tableList, setTableList ] = useState([])


  const [ foodName, setFoodName ] = useState("")
  const [ wordingSearch, setWordingSearch ] = useState("")
  
  const columns = [
    {
      name: 'ชื่อประเภทงาน',
      selector: row => row.description,
      sortable: true,
    },
    {
        name: '',
        cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoEdit(row.id)}} id={row.id}>แก้ไขข้อมูล</button>
    },
  ];


  useEffect(() => {
    window.scrollTo(0, 0)
    callGetApi()
  }, [])


  const [ employeeIdModal, setEmployeeIdModal]  = useState("")

  let [ showMessage, setShowMessage ] = useState(false)

  function gotoEdit(id ){
    setEmployeeIdModal(id)
    showModalMessage()
  }
  
  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage( is_refresh) {
    setShowMessage(false)
    if(is_refresh === true){
      callGetApi()
    }
  }

  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }



  //api
  function callGetApi( ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceWorkTypes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  function callAddApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          description: foodName
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/insertWorkType.php', requestOptions)
    .then(response => response.json())
    .then(data => validateAddApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }
  function searchKeyword(){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          word: wordingSearch
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchWorkType.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }

  function validateGetApi( data ){
    setIsShowProgress(false)
    if(data.data !== undefined ){
      let infos = (data.data)
      setTableList(infos)
    }
  } 
  function validateAddApi( data ){
    if(data.data !== undefined ){
      MySwal.fire({
        icon: 'success',
        title: <p>บันทึกสำเร็จ</p>,
        confirmButtonText: "ตกลง",
      })
      setFoodName("")
      callGetApi()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>{data.message}</p>,
        confirmButtonText: "ตกลง",
      })
    }
  } 



  function submit(){
    if(validate()){
      callAddApi()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function validate(){
    if( foodName === ""){
      return false
    }
    return true
  }

  function enterSearch( e ){
    if(e.key === 'Enter'){
      callApi()
    }
  }
  
  function callApi(){
      searchKeyword()
  }
  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessage ? <ModalEditWorkType show={showMessage} handleClose={hideModalMessage} employee_id={employeeIdModal}  ></ModalEditWorkType> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
          <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 
            <div className="w-full ">
              <span className="font-bold text-3xl">ประเภทงาน</span>
            </div>




            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-12/12  flex flex-wrap  my-12">

                <div className="w-full lg:w-6/6  flex flex-wrap ">
                  <div className="w-full  flex flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
                

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      ชื่อ
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={foodName} onChange={(e)=>{ setFoodName(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                    </div>

                    <button  onClick={submit} className="mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เพิ่ม</button>

                  </div>



                </div>


                <div className="w-full flex flex-wrap  mt-3 ">
                  <div className="w-full  bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      รายการ ประเภทงาน
                    </span>

                    <div className="w-full flex flex-wrap my-5">
                      <div className="w-full flex  flex-wrap">


                        <div className="flex-warp flex  ">
                          <div className=" flex-wrap ">
                            <input onKeyPress={enterSearch} value={wordingSearch} onChange={(e)=>{ setWordingSearch(e.target.value) }} placeholder={"ค้นหาด้วย Keyword"} className="w-72 border rounded-md px-1 py-2 content-end" type="text"  />
                          </div>
                          <div className="  ">
                            <button onClick={callApi}  className="w-content ml-4 border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >ค้นหา</button>  
                          </div>
                        </div>

                      </div>
                    </div>

                    <div  className="w-full">
                      <DataTable
                      columns={columns}
                      data={tableList}
                      pagination
                      /> 
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default WorkTypePage;