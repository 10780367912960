import React, { useEffect, useState } from 'react';
import  { useNavigate } from 'react-router-dom'

import logoApp from '../../src/logo_app.png'
import pic from '../../src/IMAG4060.jpg'

import iconHamburgerMenu from '../../src/ic_navigation_menu.png'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import ModalMobileMenu from '../../src/components/ModalMobileMenu'

function Header(props) {
  
  const navigate = useNavigate();
  const [ isShowMenu, setIsShowMenu ] = useState(1)
  let onChangeStateMenuInside = props.onChangeStateMenu

  function onChangeStateMenu(state){
    localStorage.setItem(StoredLocalConstant.IS_SHOW_NAVIGATION, state)
    setIsShowMenu(state)
    onChangeStateMenuInside(state)
  }

  function logout(){
    localStorage.setItem(StoredLocalConstant.TOKEN, "")
    localStorage.setItem(StoredLocalConstant.USER_NAME, "")
    login()
  }

  function login(){
    navigate("/")
  }

  function getUsername(){
    return localStorage.getItem(StoredLocalConstant.USER_NAME)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if(getToken() === ""){
      login()
    }
    const prevState = localStorage.getItem(StoredLocalConstant.IS_SHOW_NAVIGATION)
    if(prevState !== undefined && prevState !== null && prevState !== ''){
      onChangeStateMenu(prevState)
    } else {
      onChangeStateMenu(true)
    }

  }, [])




  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }

  let [ showMessage, setShowMessage ] = useState(false)

  function gotoEdit( ){
    showModalMessage()
  }
  
  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage( is_refresh) {
    setShowMessage(false)
  }


  return (
    <div className="w-full flex flex-wrap bg-white pl-3 py-5 border-b">
          {
            showMessage ? <ModalMobileMenu show={showMessage} handleClose={hideModalMessage}   ></ModalMobileMenu> : null
          }
      <div className="w-6/12 flex flex-wrap">
        <img 
        alt=''
        onClick={ (e)=> { onChangeStateMenu((isShowMenu == 0)? 1 : 0) }}
        src={iconHamburgerMenu} 
        className='w-12 h-12 cursor-pointer hidden lg:flex ' />
        <span className="my-auto ml-5 font-bold">ระบบติดตามงานกล้วยไม้</span>

      </div>
      <div className="w-6/12 flex flex-wrap  justify-end pr-5">
        <span onClick={showModalMessage} className="bg-gray-200 rounded-xl py-1 px-4 flex my-auto">{ getUsername() }</span>
        
      </div>
    </div> 

  )
}

export default Header;