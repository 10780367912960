import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import logoApp from '../../src/logo_app.png'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'

import pic from '../../src/img_menu.png'
import iconMenuReceipt from '../../src/ic_menu_receipt.png'
import iconMenuImport from '../../src/ic_menu_import.png'
import iconMenuExport from '../../src/ic_menu_export.png'
import iconMenuRemove from '../../src/ic_menu_remove.png'
import iconMenuReport from '../../src/ic_menu_report.png'
import iconMenuSetting from '../../src/ic_menu_setting.png'
import iconMenuMember from '../../src/ic_menu_member.png'

function SlidingMenu() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [ isShowMenuAdmin, setIsShowMenuAdmin ] = useState(false)
  

  function gotoHome(){
    navigate("/home")
  }

  function gotoImport(){
    navigate("/import")
  }

  function gotoRemove(){
    navigate("/remove")
  }

  function gotoExport(){
    navigate("/export")
  }

  function gotoBarcodeWithEmployee(){
    navigate("/barcode/employee")
  }

  function gotoCustomer(){
    navigate("/customer")
  }

  function gotoFood(){
    navigate("/food")
  }

  function gotoMainFamily(){
    navigate("/mainfamily")
  }
  
  function gotoEmployee(){
    navigate("/employee")
  }
 
  function gotoReportProduction(){
    navigate("/report/production")
  }

  function gotoReportStock(){
    navigate("/report/stock")
  }
  function gotoReportBottle(){
    navigate("/report/bottle")
  }
  function gotoReportRemove(){
    navigate("/report/remove")
  }
  function gotoReportFailPlant(){
    navigate("/report/fail-plant")
  }
  function gotoReportRemoveAll(){
    navigate("/report/remove-all")
  }
  

  function gotoWorkType(){
    navigate("/worktype")  
  }

  function gotoWorkMainType(){
    navigate("/workmaintype")  
  }


  function gotoChangePassword(){
    navigate("/employee/password")
  }

  function gotoBarcode(){
    navigate("/barcode")
  }
  

  function isStateActive( path ){
    if( path === location.pathname){
      return " bg-gray-100 "
    }
    return ""
  }

  function checkAdminGrant() {
    const grant = localStorage.getItem(StoredLocalConstant.GRANT) 
    if(grant && grant !== null && grant !== '' && grant === '3'){
      setIsShowMenuAdmin(true)
    } else {
      setIsShowMenuAdmin(false)
    }
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    checkAdminGrant()
  }, [])
  
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full  flex-wrap">
      <img alt="" src={logoApp} className="h-10 ml-3 mt-4" />


      <div onClick={gotoHome} className={" cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-6" +isStateActive("/home")}>
        <img alt="" src={iconMenuReceipt} className=" my-auto" />
        <span className="ml-2  my-auto">ใบงาน</span>
      </div>
      <div onClick={gotoBarcode} className={" cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-3" +isStateActive("/barcode")}>
        <img alt="" src={iconMenuReport} className=" my-auto" />
        <span className="ml-2  my-auto">ค้นหา Barcode</span>
      </div>
      <div onClick={gotoImport}  className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-3" +isStateActive("/import")}>
        <img alt="" src={iconMenuImport} className=" my-auto" />
        <span className="ml-2">นำเข้า</span>
      </div>
      <div onClick={gotoRemove}  className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-3" +isStateActive("/remove")}>
        <img alt="" src={iconMenuRemove} className=" my-auto" />
        <span className="ml-2">นำออก</span>
      </div>
      {/* <div onClick={gotoExport} className={"w-full flex  rounded-lg py-2 px-2 mt-3" +isStateActive("/export")}>
        <img alt="" src={iconMenuExport} className=" my-auto" />
        <span className="ml-2">ส่งมอบ</span>
      </div> */}
      <div className="w-full flex   rounded-lg py-2 px-2 mt-3">
        <img alt="" src={iconMenuReport} className=" my-auto" />
        <span className="ml-2">รายงาน</span>
      </div>
        <div onClick={gotoReportProduction} className={"hover:bg-gray-100  cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/production")}>
          <span className="ml-2">รายงาน การผลิต</span>
        </div>
        <div onClick={gotoReportStock} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/stock")}>
          <span className="ml-2">รายงาน คงคลัง</span>
        </div>
        <div onClick={gotoReportBottle} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/bottle")}>
          <span className="ml-2">รายงาน ขวด</span>
        </div>
        {/* <div onClick={gotoReportRemove} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/remove")}>
          <span className="ml-2">รายงาน ราทั้งหมด</span>
        </div> */}
        <div onClick={gotoReportFailPlant} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/fail-plant")}>
          <span className="ml-2">รายงาน เนื้อเยื่อกล้วยไม้เสียหาย</span>
        </div>
        <div onClick={gotoReportRemoveAll} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/report/remove-all")}>
          <span className="ml-2">รายงาน การนำเนื้อเยื่อกล้วยไม้ออกจากสินค้าคงคลัง</span>
        </div>
      <div className="w-full flex   rounded-lg py-2 px-2 mt-3">
        <img alt="" src={iconMenuSetting} className=" my-auto" />
        <span className="ml-2">จัดการข้อมูล</span>
      </div>
        <div onClick={gotoBarcodeWithEmployee}  className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/barcode/employee")}>
          <span className="ml-2">Barcode พนักงาน</span>
        </div>
        <div onClick={gotoCustomer}  className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/customer")}>
          <span className="ml-2">ลูกค้า</span>
        </div>
        <div  onClick={gotoFood}  className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/food")}>
          <span className="ml-2">อาหารวุ้น</span>
        </div>
        <div onClick={gotoMainFamily} className={"hover:bg-gray-100  cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/mainfamily")}>
          <span className="ml-2">สายพันธุ์หลัก</span>
        </div>
        <div onClick={gotoWorkType} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/worktype")}>
          <span className="ml-2">ประเภทงาน</span>
        </div>
        <div onClick={gotoWorkMainType} className={"hover:bg-gray-100 cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 ml-4" +isStateActive("/workmaintype")}>
          <span className="ml-2">ประเภทงานหลัก</span>
        </div>
      {
        isShowMenuAdmin ?
      <div onClick={gotoEmployee} className={"hover:bg-gray-100  cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1" +isStateActive("/employee")}>
        <img alt="" src={iconMenuMember} className=" my-auto" />
        <span className="ml-2 my-auto">พนักงาน</span>
      </div>
      : null
      }
      <div onClick={gotoChangePassword} className={"hover:bg-gray-100  cursor-pointer w-full flex  rounded-lg py-2 px-2 mt-1 " +isStateActive("/employee/password")}>
        <img alt="" src={iconMenuMember} className=" my-auto" />
        <span className="ml-2 my-auto">เปลี่ยนรหัสผ่าน</span>
      </div>
      <div className="w-full mt-12">
        <img alt="" src={pic}  />
      </div>
    </div> 
    </div> 
  )
}

export default SlidingMenu;