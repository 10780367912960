import React from 'react';
import  { useNavigate } from 'react-router-dom'

import iconHome from '../../src/ic_home.png'
import iconSearch  from '../../src/ic_search.png'
function SearchUI() {
  
  const navigate = useNavigate();



  return (
    <div className=" w-full flex flex-wrap mt-2">
    <div className=" w-3/4 flex flex-wrap">
      <input className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
    </div>
    <div className=" w-1/4 flex flex-wrap pl-3">
      <button  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
    </div>
  </div>
  )
}

export default SearchUI;