import React, { useState, useEffect } from 'react';

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import { formatDate } from '../utils/format-date-time'

import iconSearch  from '../../src/ic_search.png'

import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'

import ProgressWaiting from '../components/ProgressWaiting';

function BarcodePage() {

  const [ totalRowAll, setTotalRowAll ] = useState(10)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ tableList, setTableList ] = useState([])

  const [ barcodeSearch, setBarcodeSearch ] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const columns = [
    {
      name: 'Barcode',
      selector: row => row.barcode,
      sortable: true,
    },
    {
        name: 'รหัสใบงาน',
        selector: row => row.code,
        sortable: true,
    },
    {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'สายพันธุ์',
        selector: row => ( row.plant_family_main !== null )? row.plant_family_main : "-",
        sortable: true,
    },
    {
        name: 'ชื่อคู่ค้า',
        selector: row => ( row.customer !== null )? row.customer : "-",
        sortable: true,
    },
    {
      name: 'พนักงานทำขวด',
      selector: row => (( row.member_name !== null )? row.member_name+" " : " " )+(( row.member_name !== null )? row.member_surname: "" ),
      sortable: true,
    },
    {
      name: 'วันที่นำเข้า',
      selector: row => ( row.import_date !== null )? formatDate(row.import_date) : "-",
      sortable: true,
    },
    {
        name: 'ประเภทงานหลัก',
        selector: row => ( row.work_main_type !== null )? row.work_main_type : "-",
        sortable: true,
    },
    {
      name: 'ประเภทงาน',
      selector: row => ( row.work_type !== null )? row.work_type : "-",
      sortable: true,
    },
    {
      name: 'อาหารวุ้น',
      selector: row => ( row.food_plant !== null )? row.food_plant : "-",
      sortable: true,
    },
    {
      name: 'วันที่นำออก',
      selector: row => ( row.remove_date !== null )? formatDate(row.remove_date): "-",
      sortable: true,
    },
    {
      name: 'สาเหตุ',
      selector: row => ( row.remove_type !== null )? row.remove_type : "-",
      sortable: true,
    },
    {
      name: 'Remark',
      selector: row => ( row.remark !== null )? row.remark : "-",
      sortable: true,
    },
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

//api
function callGetApi( ){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        barcode: barcodeSearch
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchBarcode.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      setIsShowProgress(false)
      // handle error here
    }
  });
  
}


function validateGetApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined   ){
    let infos = (data.data)
    //console.log(infos)
    setTableList(infos)
  }
} 


function searchBarcode(){
  if( barcodeSearch !== "" ){
    callGetApi()
  }
  else{

  }
}

function enterSearch( e ){
  if(e.key === 'Enter'){
    searchBarcode()
  }
}
const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">ค้นหา Barcode</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                    <input onKeyPress={enterSearch} value={barcodeSearch} onChange={(e)=>{ setBarcodeSearch(e.target.value) }} placeholder={"ค้นหาด้วย Barcode"} className="w-72 border rounded-md px-1 py-2 content-end" type="text"  />
                    <button  onClick={()=>{searchBarcode()}}  className="bg-green-600 ml-3 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                  </div>
                </div>
              </div>
              
            

            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationTotalRows={totalRowAll}
              />
            </div>
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default BarcodePage;