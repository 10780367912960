import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import { formatDate } from '../utils/format-date-time'
import iconSearch  from '../../src/ic_search.png'
import iconHome from '../../src/ic_home.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import ModalFullSearch from '../../src/components/ModalFullSearch'

import DataTable from 'react-data-table-component';
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DatePicker from 'react-date-picker';

function ImportPage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ itemEmployeeList, setItemEmployeeList ] = useState([])
  const [ itemMainWorkTypeList, setItemMainWorkTypeList ] = useState([])
  const [ itemResultList, setItemResultList ] = useState([])
  
  const [ prevDate, setPrevDate ] = useState(getPrevDate())
  const [ plantCode, setPlantCode ] = useState("")
  const [ importDate, setImportDate ] = useState("")
  const [ mainWorkTypeId, setMainWorkTypeId ] = useState("")
  const [ workTypeId, setWorkTypeId ] = useState("")
  const [ plantFood, setPlantFood ] = useState("")
  const [ memberId, setMemberId ] = useState("")
  const [ barcode, setBarcode ] = useState("")

  const [ timePerDay, setTimePerDay ] = useState(0)

  const [ totalImportSuccess, setTotalImportSuccess ] = useState(0)
  

  const [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)
  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function showFilterSearch( id ){

    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }
  function handlerSearchReceipt(id, description){

    if(modalFullSearchId === 3){
      setPlantFood(description)
    }
    else if(modalFullSearchId === 4){
      setPlantCode({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 5){
      setWorkTypeId(description)
    }
  
  
    
    hideModalMessageFullSearch()
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    var date = new Date();  
    date.setDate(date.getDate() - 1);
    setImportDate(toStringDate(date) )
    callSearchEmployeeApi()
    callWorkTypeApi()
  }, [])

  

  const columns = [
    {
        name: 'Barcode',
        selector: row => row.barcode,
        wrap : true
    },
    {
        name: 'รหัสใบงาน',
        selector: row => row.reciept_id,
        wrap : true
    },
    {
        name: 'วันที่นำเข้า',
        selector: row => formatDate(row.import_date),
    },
    {
        name: 'ประเภทงานหลัก',
        selector: row => row.main_work_type_id,
    },
    {
        name: 'ประเภทงาน',
        selector: row => row.work_type_id,
    },
    
    {
      name: 'อาหารวุ้น',
      selector: row => row.food_id,
      wrap : true
    },
    {
      name: 'สถานะ',
      cell:(row)=>(row.status === "")?<span  class="font-bold text-green-500" >สำเร็จ</span>  : <span  class="font-bold text-red-500" >{ row.status }</span>,
      wrap : true
    },
    {
        name: 'พนักงาน',
        selector: row => row.name,
    },
    {
      name: '',
      cell:(row)=>(row.status === "")?<button onClick={(e)=>{ gotoDelete(row.barcode) }} class="font-bold bg-red-500 text-white px-2 py-1 rounded-md" >ลบ</button>  : '',
      wrap : true
    },
];

function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

function getPrevDate(){
  var d = new Date();
  d.setDate(d.getDate() - 1);
  var datestring = d.getFullYear() +"-"+ (((d.getMonth()+1) < 10 )? "0"+(d.getMonth()+1) : (d.getMonth()+1) ) + "-" +((d.getDate() < 10) ? "0"+d.getDate() : d.getDate())
  return datestring
}


  //api
  function callSearchEmployeeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMembers.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSearchEmployeeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callWorkTypeApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceMainWorkTypes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetWorkTypeApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callInsertApi( ){
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          "import_date" : (importDate),
          "barcode" : barcode,
          "food_description" : plantFood,
          "work_type_description" : workTypeId,
          "main_work_type_id" : mainWorkTypeId,
          "reciept_id" : plantCode.id,
          "time_per_day": timePerDay
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-import', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 
  function callDeleteApi( barcode ){
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          barcode : barcode
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-import', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteLog(barcode, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateSearchEmployeeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.member_id,
          description: `${info.name} ${info.surname}`
        })
      }
    }
    if(result.length > 0){
      setMemberId(result[0].id)
    }
    setItemEmployeeList(result)
  } 
  function validateGetWorkTypeApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }
    if(result.length > 0){
      setMainWorkTypeId(result[0].id)
    }
    setItemMainWorkTypeList(result)
  }
  function validateInsertApi( data ){
    let result = []
 
    if(data.data !== undefined ){
      const info = data.data
      // console.log(info)
      result.push({
          import_date : (importDate),
          barcode : barcode,
          food_id : plantFood,
          work_type_id : workTypeId,
          main_work_type_id :  getWorkTypeDescription(mainWorkTypeId),
          reciept_id : plantCode.description,
          status : "",
          name: info.member.name+" "+info.member.surname
        })

      setTimePerDay(data.data.time_per_day)
      
      setTotalImportSuccess(( totalImportSuccess)+1 )
      
      setBarcode("")
    }
    else {
      result.push({
          import_date : (importDate),
          barcode : barcode,
          food_id : plantFood,
          work_type_id : workTypeId,
          main_work_type_id : getWorkTypeDescription(mainWorkTypeId),
          reciept_id : plantCode.description,
          status : data.message
        })
      
      MySwal.fire({
        icon: 'warning',
        title: <p>{ data.message }</p>,
        confirmButtonText: "ตกลง",
      })
    }
    for(let i = 0; i < itemResultList.length; i++){
      result.push(itemResultList[i])
    }
    setItemResultList(result)
  }
  function validateDeleteLog( barcode, data ){
    let oldList = [...itemResultList]
    for(let i = 0; i < oldList.length; i++){
      if(oldList[i].barcode === barcode && oldList[i].status === ""){
        oldList.splice(i, 1)
        setTotalImportSuccess(( totalImportSuccess)-1 )
      }
    }
    setItemResultList(oldList)
  }

  function getWorkTypeDescription( id ){
    for(let i = 0; i < itemMainWorkTypeList.length; i++){
      if(itemMainWorkTypeList[i].id === id)
      return itemMainWorkTypeList[i].description
    }
    return ""
  }

  function submit(){
    if(validate()){
      callInsertApi()
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  
  }

  function validate(){
    if( barcode === "" || plantFood === "" || plantCode.id === "" ||  plantCode.id === undefined || importDate == null || importDate === ""  ){
      return false
    }
    return true
  }

  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }

  function toStringDate( date ){

    if(date !== "" && date !== null){

      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }

  function gotoDelete(  barcode ){

    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: '<div class="swal-content">คุณกำลังลบข้อมูล<br/>ต้องการดำเนินการต่อหรือไม่?</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef4444',
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#FFFFF',

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi(  barcode )
      }
    })
  }

  function gotoHistory(){
    navigate("/import/history")
  }

  function clearList(){
    setTimePerDay(0)
    setTotalImportSuccess(0)
    setItemResultList([])


    setMainWorkTypeId(itemMainWorkTypeList[0].id)
    setWorkTypeId('')
    setPlantFood('')
    setMemberId('')
    setBarcode('')
    var date = new Date();  
    date.setDate(date.getDate() - 1);
    setImportDate(toStringDate(date) )
    setPlantCode({
      id: '',
      description:''
    })
  }

  function onChangeDate(value){
    setTimePerDay(0)
    setImportDate(toStringDate(value))
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessageFullSearch ? <ModalFullSearch type_id={modalFullSearchId} show={showMessageFullSearch} handleClose={hideModalMessageFullSearch} handleSelected={handlerSearchReceipt}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
    
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/home")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าหลัก</span>
            </div> 
            <div className="w-full ">
              <span className="font-bold text-3xl">นำเข้า</span>
            </div>

            <div className="w-full mt-2   flex-grow flex-wrap pl-3">
                  <div className="w-full justify-end flex  pl-3">
                    <button onClick={ gotoHistory } className="border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >ประวัติการนำเข้า</button>
                  </div>
              </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-full lg:w-full  flex flex-wrap  my-12">

                <div className="w-full lg:w-2/6   flex-wrap ">
                  <div className="w-full   flex-wrap bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center">
                      รหัสใบงาน
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className="flex-grow flex-wrap">
                        <input value={ plantCode.description } onChange={ (e)=>{ setPlantCode(e.target.value) } } disabled  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      <div className="flex-none flex flex-wrap pl-3">
                        <button onClick={(e)=>{showFilterSearch(4)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                    </div>

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      วันที่นำเข้า
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        {/* <input min={ prevDate } value={ importDate } onChange={ (e)=>{ setImportDate(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                        <DatePicker format="dd/MM/y"  value={ toDate(importDate) } onChange={ (value)=>{ onChangeDate(value) } } className='w-full border rounded-md px-1 py-2 content-en' />
                      </div>
                    </div> 

                    <span className="w-full flex mt-2 text-black text-md text-center">
                      ประเภทงานหลัก
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select value={ mainWorkTypeId } onChange={ (e)=>{ setMainWorkTypeId(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                          {
                            itemMainWorkTypeList.map(function(item, index){
                              return <option value={item.id}>{item.description}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      ประเภทงาน
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className="flex-grow flex-wrap">
                        <input disabled value={ workTypeId } onChange={ (e)=>{ setWorkTypeId(e.target.value) } }    className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      <div className="flex-none flex flex-wrap pl-3">
                        <button  onClick={(e)=>{showFilterSearch(5)}} className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                    </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      อาหารวุ้น
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className="flex-grow flex-wrap">
                        <input  disabled value={ plantFood } onChange={ (e)=>{ setPlantFood(e.target.value) } }    className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      <div className="flex-none flex flex-wrap pl-3">
                        <button  onClick={(e)=>{showFilterSearch(3)}} className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                    </div>

                    {/* <span className="w-full flex mt-2 text-black text-md text-center">
                      พนักงาน
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <select value={ memberId } onChange={ (e)=>{ setMemberId(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                          {
                            itemEmployeeList.map(function(item, index){
                              return <option value={item.id}>{item.description}</option>
                            })
                          }
                        </select>
                      </div>
                    </div> */}
                  </div>



                  <div className="w-full   flex-wrap bg-white rounded-xl pl-5 py-3 pr-5 mt-3">
                    <span className="w-full flex mt-2 text-black text-md text-center">
                      Barcode
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        <input value={ barcode } onChange={ (e)=>{ setBarcode(e.target.value) } } onKeyPress={(e)=>{ if(e.key === 'Enter') { submit()  }}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                    </div>  
                    <div className="w-full flex flex-wrap">
                      <button onClick={submit}  className="w-full mt-3 border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เพิ่มรายการใหม่</button>  
                    </div>
                  
                  </div>



                </div>


                <div className="w-full lg:w-4/6 flex flex-wrap pl-0 lg:pl-3 mt-3 lg:mt-0">
                  <div className="w-full  bg-white rounded-xl pl-5 py-5 pr-5">
                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      รายการนำเข้า
                    </span>

                    <span className="w-full flex mt-2 text-black text-md text-center font-bold">
                      จำนวนทั้งหมดที่นำเข้าสำเร็จ { totalImportSuccess } 
                    </span>

                    <div className="w-full flex justify-end mt-2">
                      <button onClick={clearList}  className="w-content mt-3 border px-3 py-2 text-white border-red-500 rounded-lg bg-red-500 font-bold" >ล้างรายการ</button>  
                    </div>

                    <div  className="w-full">
                      <DataTable
                        pagination={true}
                        columns={columns}
                        data={itemResultList}
                        />
                          
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>  
        </div>  

      </div>
  )
}

export default ImportPage;