import moment from "moment/moment";

export function formatDate( date ) {
  // const dateTimeArray = date.split(' ');
  // if(dateTimeArray.length >= 1){
  //   const dateArray = dateTimeArray[0].split('-');
  //   if (dateArray.length >= 3) {
  //     // DD/MM/YYYY
  //     return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
  //   }
  // }
  return moment(date).format("DD/MM/YYYY");
}

export function formatDateExcel( date ) {
  const dateTimeArray = date.split('/');
  if(dateTimeArray.length == 3){
    return new Date( Date.UTC(dateTimeArray[2], parseInt(dateTimeArray[1])-1, dateTimeArray[0] ))
  }
  return '';
}

export function formatMonthExcel( date ) {
  const dateTimeArray = date.split('/');
  if(dateTimeArray.length == 2){
    return new Date( Date.UTC(dateTimeArray[1], parseInt(dateTimeArray[0])-1 ))
  }
  return '';
}


export function formatMonth( YM ) {
  const dateTimeArray = YM.split('-');
  if(dateTimeArray.length === 2){
    return `${dateTimeArray[1]}/${dateTimeArray[0]}`
    
  }
  return '';
}
