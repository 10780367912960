import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'
import { formatDate } from '../utils/format-date-time'

import SearchUI from '../../src/components/SearchUI'

import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'

import iconSearch  from '../../src/ic_search.png'
import DatePicker from 'react-date-picker';
import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProgressWaiting from '../components/ProgressWaiting';
import moment from 'moment';
function RemoveHistoryPage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const location = useLocation()

  const [ tableList, setTableList ] = useState([])
  const [ filterList, setFilterList ] = useState([])
  const [ filter, setFilter ] = useState(location.state.filter_all)
  const [ filterData, setFilterData ] = useState(location.state.filter)

  const [ barcodeSearch, setBarcodeSearch ] = useState("")

  const [ removeDate, setRemoveDate ] = useState(location.state.filter.remove_date)
  const [ reason, setReason ] = useState("")
  const [ itemReasonList, setItemReasonList ] = useState([])
  const [ remark, setRemark ] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log('useEffect')
    console.log(location.state.filter_all)
    console.log('useEffect:data')
    console.log(location.state.filter)
    setFilter(location.state.filter_all)

    callReasonApi()
     getDefaultFromStorage()
  }, [])

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)

  const handlePageChange = page => {
		setPage(page);
    // call
    callSearchReceiptDetail(filterList, page, perPage )
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    callSearchReceiptDetail(filterList, page, newPerPage )
	};


  function callReasonApi(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourcePlantRemoveTypes.php', requestOptions)
    .then(response => response.json())
    .then(data => validateReasonApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });

  }

  function validateReasonApi( data ){
    let result = []
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }
    if(result.length > 0){
      setReason(result[0].id)
    }
    setItemReasonList(result)
  } 


  function saveFilterToStorage( filterList ){
    let json = {
      page: "report_history_remove_group_Detail",
      filter: filterList
    }
    localStorage.setItem("filter_orchid", JSON.stringify(json));
  }
  function getDefaultFromStorage(){
    let json = localStorage.getItem("filter_orchid")
    if(json === null){
      let defaultFilter = getDefaultFilter()
      setFilterList(defaultFilter)
      callSearchReceiptDetail(defaultFilter, page, perPage)
    }
    else{

      let obj = JSON.parse(json)
      if(obj.page === "report_history_group"){
        addOnFilter(obj.filter)
        callSearchReceiptDetail(obj.filter, page, perPage)
      }
      else{
        let defaultFilter = getDefaultFilter()
        setFilterList(defaultFilter)
        callSearchReceiptDetail(defaultFilter, page, perPage)
      }
    }
  }

  function addOnFilter(oldFilter){
   
    oldFilter[6].value.data.id = location.state.filter.create_by
    oldFilter[6].value.data.description = location.state.filter.member_name +" "+location.state.filter.member_surname
    oldFilter[8].value.data.description = ""
    oldFilter[9].value.data.description = ""
    oldFilter[10].value.data.id = location.state.filter.plant_remove_type_id
    oldFilter[10].value.data.description = location.state.filter.remove_type
    oldFilter[11].value.data.description = location.state.filter.remove_date
    oldFilter[12].value.data.description = location.state.filter.remove_date
    if(oldFilter.length < 14){

      
      oldFilter.push({
        id: "timePerDay",
        value: {
          data : {
            id: "",
            description : "1"
          },
          is_match_all : true
        }
      })
   }

    oldFilter[13].value.data.id = location.state.filter.time_per_day
    oldFilter[13].value.data.description = location.state.filter.time_per_day

    setFilterList(oldFilter)
  }

  function gotoDetail( data ){
    navigate("/remove/history/detail", {
      state : {
        filter : data
      }
    })
  }
  

 



  function getDefaultFilter(  ){
 
    let oldArray = new Array()
    
    oldArray.push({
      id: "plantName",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "plantCode",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "familyMain",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "workType",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "food",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "customer",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "employee",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "mainTask",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "importEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "reason",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "exportStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "exportEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "timePerDay",
      value: {
        data : {
          id: "1",
          description : "1"
        },
        is_match_all : true
      }
    })

    return oldArray
  }

  function getMonthYearDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    return   moment().add(-1, 'days').format("YYYY-MM-DD")
  } 
  function getDateDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    let d = date.getDate()
    return   ( date.getFullYear())+"-"+(( m < 10 ) ?  "0"+m : m )+"-"+(( d < 10 ) ?  "0"+d : d )
  } 


  const columns = [
    {
      name: 'วัน/เดือน/ปี ที่นำเข้า',
      selector: row => formatDate(row.import_date),
      sortable: true,
      wrap : true
    },
    {
      name: 'วัน/เดือน/ปี ที่นำออก',
      selector: row => formatDate(row.remove_date),
      sortable: true,
      wrap : true
    },
    {
        name: 'รหัสใบงาน',
        selector: row => row.receipt_code,
        sortable: true,
        wrap : true
    },
    {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.receipt_name,
        sortable: true,
        wrap : true
    },
    // {
    //     name: 'สายพันธุ์',
    //     selector: row => ( row.plant_family_main !== null )? row.plant_family_main : "",
    //     sortable: true,
    // },
    {
        name: 'ประเภทงานหลัก',
        selector: row => row.main_work_type,
        sortable: true,
        wrap : true
    },
    {
        name: 'ประเภทงาน',
        selector: row => row.work_type,
        sortable: true,
        wrap : true
    },
    {
        name: 'อาหารวุ้น',
        selector: row => row.food,
        sortable: true,
        wrap : true
    },
    // {
    //     name: 'ชื่อคู่ค้า',
    //     selector: row => ( row.customer_name !== null )? row.customer_name : "",
    //     sortable: true,
    // },
    {
      name: 'สาเหตุ',
      selector: row => row.remove_type,
      sortable: true,
      wrap : true,
  },
    {
        name: 'จำนวนนำออก',
        selector: row => row.total_remove,
        sortable: true,
        wrap : true,
        right: true
    },
    {
      name: 'ชื่อ-นามสกุลพนักงาน',
      selector: row => `${row.member_name} ${row.member_surname}`,
      sortable: true,
      wrap : true
    },
    {
        name: '',
        cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoDetail(row)}} id={row.receipt_id}>รายละเอียด</button>
    },
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

function callGetBarcodeApi( ){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        barcode: barcodeSearch
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getLogPlantImportByBarcode.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetBarcodeApi(data), (error) => {
    if (error) {
      // handle error here
      setIsShowProgress(false)
    }
  });
  
}
function callSearchReceiptDetail( filter, page, perPage){

  if(filterData) {
    if(filter.length >= 11 ){

      filter[10].value.data.id = filterData.plant_remove_type_id
      filter[10].value.data.description = filterData.plant_remove_type_id
    
    }
    if(filter.length >= 14 ){

      filter[13].value.data.id = filterData.time_per_day
      filter[13].value.data.description = filterData.time_per_day
    
    }
  }


  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: perPage
      }
    )
  };


  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReportHistoryRemoveFromGroup.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
      setIsShowProgress(false)
    }
  });
  
}



function validateGetApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined   ){
    let infos = (data.data.data)

    if(infos.length > 0) {
      setReason(parseInt(location.state.filter.plant_remove_type_id))
    }
    setTableList(infos)
    setTotalRowAll(data.data.total_all)
  }
} 

function validateGetBarcodeApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined ){
    let infos = (data.data)
    let result = []
    if(infos.code !== undefined){
      result.push(infos)
    }
    setTableList(result)
    setBarcodeSearch("")
  }
  else{
    MySwal.fire({
      icon: 'warning',
      title: <p>{ data.message }</p>,
      confirmButtonText: "ตกลง",
    })
  }
} 

function searchBarcode(){
  if( barcodeSearch === "" ){
    callSearchReceiptDetail(filterList,  page, perPage )
  }
  else{
    callGetBarcodeApi()
  }
}


function getTags(){
  return filterList
}

const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}



function toDate( dateStr ){
  if(dateStr !== "" && dateStr !== null){
    var date = new Date(dateStr)
    return date
  }
  return null
}



function onChangeDate(value){
  setRemoveDate(toStringDate(value))
}


function toStringDate( date ){
  if(date !== "" && date !== null){

    const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
    return str
  }
  return null
}

function validate(){
  if( removeDate === null || removeDate === ""  ){
    return false
  }
  return true
}

function save(){
  if(validate()){
    callUpdateApi(  )
    
  }
  else{

    MySwal.fire({
      icon: 'warning',
      title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
      confirmButtonText: "ตกลง",
    })
  }
}


function callUpdateApi() {
    
  const body = {
    token: getToken(),
    filter: JSON.stringify(filter),
    "remove_date" : (removeDate),
    "plant_remove_type_id" : reason,
    "reciept_id" : "",
    "remark" : remark,
    time_per_day: filterList.length >= 14 ? filterList[13].value.data.description : ''
  }
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(body)
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateLogPlantRemoveGroupAll.php', requestOptions)
  .then(response => response.json())
  .then(data => validateUpdateLog(data), (error) => {
    if (error) {
      // handle error here
    }
  });
}
function validateUpdateLog( data ){
 navigate('/remove/group' , {replace: true })
}


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >

        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">ประวัติการนำออก</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                
                    {/* <button  onClick={()=>{setShowMessage(true)}} className="ml-3 mt-3 lg:mt-0 border px-3 py-2 border-green-500 rounded-lg text-green-500 font-bold" >ค้นหาโดยละเอียด</button> */}
                  </div>
                </div>
              </div>
              
            

            </div>

            <div className="w-full flex flex-wrap mt-5">
              {/* <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( item.value.data.description !== "" ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                          <button onClick={(e)=>{ removeTag(index) }} className="flex my-auto" ><span className="text-xs text-black font-bold ml-3">X</span></button>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div> */}

              <div className=" flex flex-wrap mt-2">
              {
                  getTags().map(function(item, index){
                    if( (item.value.data.description) !== undefined && ( (item.value.data.description) !== "" && (item.value.data.description).trim() !== ""  )  ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                        </div>
                      )
                    }
                  })
                }
             
              {/* <div className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                <span className="flex my-auto text-xs">{filterRemoveDate}</span>
              </div>
              
              <div className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                <span className="flex my-auto text-xs">{filterMemberMadeDesc}</span>
              </div>

                           
              <div className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                <span className="flex my-auto text-xs">{filterTimePerDay}</span>
              </div>
             
              <div className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                <span className="flex my-auto text-xs">{filterRemoveTypeDesc}</span>
              </div>     */}
              
        
              
              </div>


            </div>

            <div className="w-full bg-gray-200  flex flex-wrap mt-5">
              <div className=" w-full lg:w-1/2 flex  mt-5 flex-wrap px-3">
                <div className="w-full flex flex-wrap bg-white py-6 px-6  rounded-lg">
                  <div className="w-full flex flex-wrap ">
                    <span className="font-bold text-lg">แก้ไขข้อมูลรายการทั้งหมด</span>
                  </div>


                    <span className="w-full flex mt-2 text-black text-md text-center">
                      วันที่นำออก
                    </span>
                    <div className=" w-full flex flex-wrap mt-1">
                      <div className=" w-full flex flex-wrap">
                        {/* <input value={ removeDate } onChange={ (e)=>{ setRemoveDate(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                        <DatePicker format="dd/MM/y"  value={ toDate(removeDate) }onChange={ (value)=>{ onChangeDate(value) }  } className='w-full border rounded-md px-1 py-2 content-en' />
                      </div>
                    </div>

                      <span className="w-full flex mt-2 text-black text-md text-center">
                        สาเหตุ
                      </span>
                      <div className=" w-full flex flex-wrap mt-1">
                        <div className=" w-full flex flex-wrap">
                          <select value={ reason } onChange={ (e)=>{ setReason(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                            {
                              itemReasonList.map(function(item, index){
                                return <option value={item.id}>{item.description}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>


                      <span className="w-full flex mt-2 text-black text-md text-center">
                        Remark
                      </span>
                      <div className=" w-full flex flex-wrap mt-1">
                        <div className=" w-full flex flex-wrap">
                          <input value={ remark } onChange={ (e)=>{ setRemark(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                        </div>
                      </div>  


                      <div className=" w-full flex flex-wrap mt-2">
                        <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                      </div>

                </div>
              </div>

            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
              />
            </div>
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default RemoveHistoryPage;