import React, { useState, useEffect } from 'react'

import iconClose from '../../src/ic_modal_close.svg'
import iconSearch  from '../../src/ic_search.png'
import ModalFullSearch from '../../src/components/ModalFullSearch'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DatePicker from 'react-date-picker';

function ModalFilter(props) {

  let [ showHideClassName ] = useState(
    props.show ? "block" : "hidden"
  )

  let [ buttonTitle ] = useState(props.button)
  let [ modalTitle ] = useState(props.title)
  let [ modalMessage ] = useState(props.message)
  
  let [ page ] = useState(props.page)


  let handleClose = props.handleClose
  let handleFullSearch = props.handleFullSearch
  let handleSubmit = props.handleSubmit


  

  const [ itemEmployeeList, setItemEmployeeList ] = useState([])
  const [ itemMainTaskList, setItemMainTaskList ] = useState([])
  const [ itemWorkTypeList, setItemWorkTypeList ] = useState([])
  const [ itemReasonList, setItemReasonList ] = useState([])
  
  const [ plantCode, setPlantCode ] = useState({id:"", description:""})
  const [ plantName, setPlantName ] = useState({id:"", description:""})
  const [ customer, setCustomer ] = useState({id:"", description:""})
  const [ plantMainFamily, setPlantMainFamily ] = useState({id:"", description:""})
  const [ plantFood, setPlantFood ] = useState({id:"", description:""})
  const [ mainTask, setMainTask ] = useState({id:"", description:""})
  const [ workType, setWorkType ] = useState({id:"", description:""})
  const [ employee, setEmployee ] = useState({id:"", description:""})
  const [ importStartDate, setImportStartDate ] =  useState({id:"", description:""})
  const [ importEndDate, setImportEndDate ] =  useState({id:"", description:""})
  const [ reason, setReason ] = useState({id:'', description:""})
  const [ exportStartDate, setExportStartDate ] =  useState({id:"", description:""})
  const [ exportEndDate, setExportEndDate ] =  useState({id:"", description:""})

  const [ isMatchAllPlantCode, setIsMatchAllPlantCode ] = useState(false)
  const [ isMatchAllPlantName, setIsMatchAllPlantName ] = useState(false)
  const [ isMatchAllPlantMainFamily, setIsMatchAllPlantMainFamily ] = useState(false)
  const [ isMatchAllPlantFood, setIsMatchAllPlantFood ] = useState(false)


  const [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)

  const [ filterMainTasks, setFilterMainTasks ] = useState([])

  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function showFilterSearch( id ){

    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }
  function handlerSearchReceipt(id, description){

    if(modalFullSearchId === 0){
      setPlantName({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 1){
      setCustomer({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 2){
      setPlantMainFamily({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 3){
      setPlantFood({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 4){
      setPlantCode({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 6){
      setEmployee({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 7){
      setMainTask({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 101){
      const prevItems = [...filterMainTasks]
      let isHas = false
      for(let i = 0; i < prevItems.length; i++){
        if(prevItems[i].id === id){
          isHas = true
        }
      }
      if(isHas === false){
        prevItems.push({
          id: id,
          description:description
        })
      }
      setFilterMainTasks(prevItems)
    }
    hideModalMessageFullSearch()
  }

  function removeFilterMainTask(id) {
    const prevItems = [...filterMainTasks]
    let indexDelete = -1
    for(let i = 0; i < prevItems.length; i++){
      if(prevItems[i].id === id){
        indexDelete = i
      }
    }
    if(indexDelete >= 0){
      prevItems.splice( indexDelete, 1 )
    }

    setFilterMainTasks(prevItems)
  }


  function setEmployeeSelected( index ){
    setEmployee({
      id: itemEmployeeList[index].id,
      description: `${itemEmployeeList[index].description}`
    })
  }
  function setMainTaskSelected( index ){
    setMainTask({
      id: itemMainTaskList[index].id,
      description: itemMainTaskList[index].description
    })
  }
  function setWorkTypeSelected( index ){
    setWorkType({
      id: itemWorkTypeList[index].id,
      description: itemWorkTypeList[index].description
    })
  }
  function setReasonSelected( index ){
    setReason({
      id: itemReasonList[index].id,
      description: itemReasonList[index].description
    })
  }
  function getMainTaskIndex( id ){
    for(let i = 0; i < itemMainTaskList.length; i++){
      if(itemMainTaskList[i].id === id ){
        return i
      }
    }
    return 0
  }
  function getWorkTypeIndex( id ){
    for(let i = 0; i < itemWorkTypeList.length; i++){
      if(itemWorkTypeList[i].id === id ){
        return i
      }
    }
    return 0
  }
  function getReasonIndex( id ){
    for(let i = 0; i < itemReasonList.length; i++){
      if(itemReasonList[i].id === id ){
        return i
      }
    }
    return 0
  }
  function getEmployeeIndex( id ){
    for(let i = 0; i < itemEmployeeList.length; i++){
      console.log(i+" : "+(itemEmployeeList[i].id)+" === "+ id)
      if(itemEmployeeList[i].id === id ){
        return i
      }
    }
    return 0
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    callSearchWorkTypeApi()
    callSearchMainTaskApi()
    callReasonApi()
    
    let values = props.default
    if( values.length > 0 ){

      for(let n = 0; n < values.length; n++){
        const v = values[n]
        // console.log('value['+n+']=')
        console.log(v)
        const vId = v.id
        const id = v.value.data.id
        const desc = v.value.data.description
        if (vId === 'plantName'){
          const is_match_all = v.value.is_match_all
          setIsMatchAllPlantName(is_match_all)

          setPlantName({
            id: id,
            description: desc
          })
        } else if (vId === 'plantCode'){
          const is_match_all = v.value.is_match_all
          setIsMatchAllPlantCode(is_match_all)

          setPlantCode({
            id: id,
            description: desc
          })
        } else if (vId === 'familyMain'){

          const is_match_all = v.value.is_match_all
          setIsMatchAllPlantMainFamily(is_match_all)

          setPlantMainFamily({
            id: id,
            description: desc
          })
        } else if (vId === 'workType'){
          setWorkType({
            id: id,
            description: desc
          })
        } else if (vId === 'food'){
          const is_match_all = v.value.is_match_all
          setIsMatchAllPlantFood(is_match_all)

          setPlantFood({
            id: id,
            description: desc
          })
        } else if (vId === 'customer'){
          setCustomer({
            id: id,
            description: desc
          })
        } else if (vId === 'employee'){
          setEmployee({
            id: id,
            description: desc
          })
        } else if (vId === 'mainTask'){
          setMainTask({
            id: id,
            description: desc
          })
        } else if (vId === 'importStartDate'){
          setImportStartDate({
            id: id,
            description: desc
          })
        } else if (vId === 'importEndDate'){
          setImportEndDate({
            id: id,
            description: desc
          })
        } else if (vId === 'reason'){
          setReason({
            id: id,
            description: desc
          })
        } else if (vId === 'exportStartDate'){
          setExportStartDate({
            id: id,
            description: desc
          })
        } else if (vId === 'exportEndDate'){
          setExportEndDate({
            id: id,
            description: desc
          })
        } else if (vId === 'mainTaskMultiple'){
          const prevItems = []
          const infos = v.value.data
          console.log('infos')
          console.log(infos)
          for (let b = 0; b < infos.length; b++) {
            
            prevItems.push(infos[b])
          }
          console.log('prevItems')
          console.log(prevItems)
          setFilterMainTasks(prevItems)
        }


      }

    }

  }, [])


  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  
    //api
    function callSearchEmployeeApi( ){
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          { 
            token: getToken(),
          }
        )
      };
      fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getMembers.php', requestOptions)
      .then(response => response.json())
      .then(data => validateSearchEmployeeApi(data), (error) => {
        if (error) {
          // handle error here
        }
      });
    } 
    function callSearchMainTaskApi( ){
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          { 
            token: getToken(),
          }
        )
      };
      fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceMainWorkTypes.php', requestOptions)
      .then(response => response.json())
      .then(data => validateSearchMainTaskApi(data), (error) => {
        if (error) {
          // handle error here
        }
      });
    } 
    function callSearchWorkTypeApi( ){
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          { 
            token: getToken(),
          }
        )
      };
      fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceWorkTypes.php', requestOptions)
      .then(response => response.json())
      .then(data => validateSearchWorkTypeApi(data), (error) => {
        if (error) {
          // handle error here
        }
      });
    } 
    function validateSearchEmployeeApi( data ){
      let result = []
      if(data.data !== undefined ){
        let infos = (data.data)
        result.push({
          id: "",
          description: `ไม่ระบุ`
        })
        for(let i = 0; i < infos.length; i++){
          let info  = infos[i]
  
          result.push({
            id: info.member_id,
            description: `${info.name} ${info.surname}`
          })
        }
      }
  
      setItemEmployeeList(result)
    } 
    function validateSearchMainTaskApi( data ){
      let result = []
      if(data.data !== undefined ){
        let infos = (data.data)
        result.push({
          id: "",
          description: `ไม่ระบุ`
        })
        for(let i = 0; i < infos.length; i++){
          let info  = infos[i]
  
          result.push({
            id: info.id,
            description: `${info.description}`
          })
        }
      }
  
      setItemMainTaskList(result)
    } 
    function validateSearchWorkTypeApi( data ){
      let result = []
      if(data.data !== undefined ){
        let infos = (data.data)
        result.push({
          id: "",
          description: `ไม่ระบุ`
        })
        for(let i = 0; i < infos.length; i++){
          let info  = infos[i]
  
          result.push({
            id: info.id,
            description: `${info.description}`
          })
        }
      }
  
      setItemWorkTypeList(result)
    } 




    function callReasonApi(){
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          { 
            token: getToken(),
          }
        )
      };
      fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourcePlantRemoveTypes.php', requestOptions)
      .then(response => response.json())
      .then(data => validateReasonApi(data), (error) => {
        if (error) {
          // handle error here
        }
      });
  
    }
  function validateReasonApi( data ){
    let result = []
    
    result.push({
      id: '0',
      description: 'ทั้งหมด'
    })
    if(data.data !== undefined ){
      let infos = (data.data)
      for(let i = 0; i < infos.length; i++){
        let info  = infos[i]

        result.push({
          id: info.id,
          description: info.description
        })
      }
    }
    if(result.length > 0){
      
    }
    setItemReasonList(result)
  } 

    function submit(){
      let filter = {
        importStartDate: {
          data: importStartDate,
          is_match_all: true
        }, 
        importEndDate: {
          data: importEndDate,
          is_match_all: true
        },
        name: {
          data: plantName,
          is_match_all: isMatchAllPlantName
        },
        code: {
          data: plantCode,
          is_match_all: isMatchAllPlantCode
        },
        familyMain: {
          data: plantMainFamily,
          is_match_all: isMatchAllPlantMainFamily
        },
        mainTask: {
          data: mainTask,
          is_match_all: true
        },
        workType: {
          data: workType,
          is_match_all: true
        },
        food: {
          data: plantFood,
          is_match_all: isMatchAllPlantFood
        },
        customer: {
          data: customer,
          is_match_all: true
        },
        employee: {
          data: employee,
          is_match_all: true
        },
        reason: {
          data: reason,
          is_match_all: true
        },
        exportStartDate: {
          data: exportStartDate,
          is_match_all: true
        }, 
        exportEndDate: {
          data: exportEndDate,
          is_match_all: true
        },
        mainTaskMultiple: {
          data: filterMainTasks,
          is_match_all: true
        },
      }
      handleSubmit(filter)
      
    }


  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }

  function toStringDate( date ){
    if(date !== "" && date !== null){

      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }
  
  return (
    
        <div className={' w-full fixed z-50 inset-0 overflow-y-auto ' + showHideClassName}  aria-labelledby="modal-title" role="dialog" aria-modal="true">
          {
            showMessageFullSearch ? <ModalFullSearch type_id={modalFullSearchId} show={showMessageFullSearch} handleClose={hideModalMessageFullSearch} handleSelected={handlerSearchReceipt}  ></ModalFullSearch> : null
          }
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle xl:max-w-xl  w-full">
              <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex mb-2 justify-end">
                    <button><img onClick={handleClose}  alt="" src={iconClose} className="flex flex-wrap " /></button>
                </div>

  

                <div className=" w-full mb-2">
                  <div className=" w-full flex flex-wrap mt-2">
                    <div className=" w-1/2 flex flex-wrap mt-2">
                      <span className="w-full flex mt-2 text-black text-xl  font-semibold">
                        ค้นหาโดยละเอียด
                      </span>
                    </div>
                    <div className=" w-1/2 flex flex-wrap mt-2 justify-end">
                      <button onClick={(e)=>{ submit() }}  className={"px-5 flex text-white bg-green-500 justify-center py-2 rounded-lg"} >ค้นหา</button>
          
                    </div>
                  </div>


                  {
                    ( page === "home" || "page" === "remove-history") ? null 
                    : (
                      <div className=" w-full flex flex-wrap">
                        
                        {
                          (page === "remove-history" || page === "report-remove-all" || page === "report_history_group" || page === "report-plant-fail")?

                            <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            วันที่นำออก
                            </span>
                          :

                            <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            วันที่นำเข้า
                            </span>
                        }
                        <div className=" w-full flex flex-wrap">
                          <div className=" w-full flex flex-wrap">
                            <div className="flex-grow flex-wrap">
                              {/* <input value={importStartDate.description} onChange={(e)=>{ setImportStartDate({ id: "", description: e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                              <DatePicker format="dd/MM/y"  value={ toDate(importStartDate.description) } onChange={ (value)=>{ setImportStartDate({ id: "", description: toStringDate(value)}) } } className='w-full border rounded-md px-1 py-2' />
                            </div>
                          </div>
                          <div className=" w-full flex flex-wrap">
                            <div className="flex-grow flex-wrap mt-3">
                              {/* <input value={importEndDate.description} onChange={(e)=>{ setImportEndDate({ id: "", description: e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                              <DatePicker format="dd/MM/y"  value={ toDate(importEndDate.description) } onChange={ (value)=>{ setImportEndDate({ id: "", description: toStringDate(value)}) } } className='w-full border rounded-md px-1 py-2 ' />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    (true) ? null 
                    : (
                      <div className=" w-full flex flex-wrap">
                        <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                          วันที่นำออก
                        </span>
                        <div className=" w-full flex flex-wrap">
                          <div className=" w-full flex flex-wrap">
                            <div className="flex-grow flex-wrap">
                              {/* <input value={importStartDate.description} onChange={(e)=>{ setImportStartDate({ id: "", description: e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                              <DatePicker format="dd/MM/y"  value={ toDate(exportStartDate.description) } onChange={ (value)=>{ setExportStartDate({ id: "", description: toStringDate(value)}) } } className='w-full border rounded-md px-1 py-2' />
                            </div>
                          </div>
                          <div className=" w-full flex flex-wrap">
                            <div className="flex-grow flex-wrap mt-3">
                              {/* <input value={importEndDate.description} onChange={(e)=>{ setImportEndDate({ id: "", description: e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                              <DatePicker format="dd/MM/y"  value={ toDate(exportEndDate.description) } onChange={ (value)=>{ setExportEndDate({ id: "", description: toStringDate(value)}) } } className='w-full border rounded-md px-1 py-2 ' />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                      (page === 'report_history_group')? null:
                      <div className=" w-full flex flex-wrap">
                          <span className="w-full flex mt-2 text-gray-500 text-md text-center">
                            รหัสพันธุ์ไม้
                          </span>
                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <div className="flex-grow flex-wrap">
                                <input value={plantCode.description} onChange={(e)=>{ setPlantCode({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                              </div>
                              <div className="flex-none flex flex-wrap pl-3">
                                <button onClick={(e)=>{showFilterSearch(4)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                              </div>
                            </div>
                          </div>
                          <div className=" w-full flex flex-wrap mt-2">
                            <input  checked={ (isMatchAllPlantCode)? 'checked' : ''} onChange={ (e)=>{ setIsMatchAllPlantCode( (!isMatchAllPlantCode) ) } } className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                          </div>
                            
                          <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            ชื่อพันธุ์ไม้
                          </span>

                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <div className="flex-grow flex-wrap">
                                <input value={plantName.description} onChange={(e)=>{ setPlantName({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                              </div>
                              <div className="flex-none flex flex-wrap pl-3">
                                <button onClick={(e)=>{showFilterSearch(0)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                              </div>
                            </div>
                          </div>
                          {/* <div className=" w-full flex flex-wrap mt-2">
                            <input checked={ (isMatchAllPlantName)? 'checked' : ''}  onChange={ (e)=>{ setIsMatchAllPlantName( (!isMatchAllPlantName) ) } }  className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                          </div> */}

                          <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            สายพันธุ์หลัก
                          </span>

                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <div className="flex-grow flex-wrap">
                                <input value={plantMainFamily.description} onChange={(e)=>{ setPlantMainFamily({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                              </div>
                              <div className="flex-none flex flex-wrap pl-3">
                                <button  onClick={(e)=>{showFilterSearch(2)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                              </div>
                            </div>
                          </div>
                          {/* <div className=" w-full flex flex-wrap mt-2">
                            <input checked={ (isMatchAllPlantMainFamily)? 'checked' : ''}  onChange={ (e)=>{ setIsMatchAllPlantMainFamily( (!isMatchAllPlantMainFamily) ) } }  className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                          </div> */}
                      </div>
                  }

                  {
                    ( page === "report_production" || page === "report_stock") ?
                    <div className=" w-full flex flex-wrap">
                      <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                        ประเภทงานหลัก (เลือกได้มากกว่า 1 รายการ)
                      </span>
                      <div className=" w-full flex flex-wrap">
                        <div className=" w-full flex flex-wrap">
                          <div className='flex flex-wrap w-full my-2'>
                            {
                              filterMainTasks.map(function(item, index) {
                                return (
                                  <span className=' bg-gray-300 rounded-2xl px-2 py-1 text-xs my-1 mr-2'>
                                    {item.description}
                                    <span 
                                    onClick={() => { removeFilterMainTask(item.id) }}
                                    className='ml-2 text-white cursor-pointer font-bold'>X</span>
                                  </span>
                                )
                              })
                            }
                          </div>

                          <div className="flex-full flex flex-wrap ">
                            <button onClick={(e)=>{showFilterSearch(101)}}  className="bg-green-600  text-white px-4 py-1 rounded-md">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    ( page === "home" ||    ( page === "report_production" || page === "report_stock") ) ? null 
                    : (
                      <div className=" w-full flex flex-wrap">

                          <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            ประเภทงานหลัก
                          </span>
                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <div className="flex-grow flex-wrap">
                                <input value={mainTask.description} onChange={(e)=>{ setMainTask({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                              </div>
                              <div className="flex-none flex flex-wrap pl-3">
                                <button onClick={(e)=>{showFilterSearch(7)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                              </div>
                            </div>
                          </div>
                          {/* <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <select value={getMainTaskIndex(mainTask.id)} onChange={ (e)=>{ setMainTaskSelected(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text">
                                {
                                  itemMainTaskList.map(function(item, index){
                                    return <option value={index}>{item.description}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div> */}

                          <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            ประเภทงาน
                          </span>
                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <select value={getWorkTypeIndex(workType.id)} onChange={ (e)=>{ setWorkTypeSelected(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text">
                                {
                                  itemWorkTypeList.map(function(item, index){
                                    return <option value={index}>{item.description}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>

                          <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                            อาหารวุ้น
                          </span>
                          <div className=" w-full flex flex-wrap">
                            <div className=" w-full flex flex-wrap">
                              <div className="flex-grow flex-wrap">
                                <input value={plantFood.description} onChange={(e)=>{ setPlantFood({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                              </div>
                              <div className="flex-none flex flex-wrap pl-3">
                                <button onClick={(e)=>{showFilterSearch(3)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                              </div>
                            </div>
                          </div>
                          {/* <div className=" w-full flex flex-wrap mt-2">
                            <input checked={ (isMatchAllPlantFood)? 'checked' : ''}  onChange={ (e)=>{ setIsMatchAllPlantFood( (!isMatchAllPlantFood) ) } } className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                          </div> */}
                      </div>
                  )
                }

                  <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                    ชื่อคู่ค้า
                  </span>
                  <div className=" w-full flex flex-wrap">
                    <div className=" w-full flex flex-wrap">
                      <div className="flex-grow flex-wrap">
                        <input disabled value={customer.description} onChange={(e)=>{ setCustomer({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                      </div>
                      <div className="flex-none flex flex-wrap pl-3">
                        <button onClick={(e)=>{showFilterSearch(1)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                      </div>
                    </div>
                  </div>
                  {/* <div className=" w-full flex flex-wrap mt-2">
                    <input className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                  </div> */}

                  {
                    ( page === "home") ? null 
                    : (
                      <div className=" w-full flex flex-wrap">
                        <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                          { page === "report_history_group" ? 'พนักงานยิงออก' : 'พนักงานทำขวด' }
                        </span>
                      
                        <div className=" w-full flex flex-wrap">
                          <div className=" w-full flex flex-wrap">
                            <div className="flex-grow flex-wrap">
                              <input disabled value={employee.description} onChange={(e)=>{ setEmployee({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                            </div>
                            <div className="flex-none flex flex-wrap pl-3">
                              <button onClick={(e)=>{showFilterSearch(6)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                            </div>
                          </div>
                        </div>
                        {/* <div className=" w-full flex flex-wrap mt-2">
                          <input className="text-sm my-auto" type="checkbox" /><span className="ml-3 text-sm my-auto">ตรงทุกคำค้นหา</span>
                        </div> */}
                      </div>
                    )
                  }

                  {
                    (page === 'report-remove-all' || page === 'remove-history' || page === 'report_history_group')?

                    <div className=" w-full flex flex-wrap">
                      <span className="w-full flex mt-5 text-gray-500 text-md text-center">
                      สาเหตุ
                      </span>
                      <div className=" w-full flex flex-wrap">
                        <div className=" w-full flex flex-wrap">
                        <select value={getReasonIndex(reason.id)} onChange={ (e)=>{ setReasonSelected(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text">
                        
                            {
                              itemReasonList.map(function(item, index){
                                return <option value={item.id}>{item.description}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }

                </div>   
              </div>
            </div>
          </div>
        </div>
        
  );
    
}
export default ModalFilter;