import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'

import iconSearch  from '../../src/ic_search.png'

import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProgressWaiting from '../components/ProgressWaiting';
import { exportFileExcel } from '../utils/export-file'
function HomePage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)

  const handlePageChange = page => {
		setPage(page);
    // call
    if ( barcodeSearch === '') {
      callSearchReceiptDetail(filterList, page, perPage )
    } else {
      callGetBarcodeApi( page, perPage)
    }
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    if ( barcodeSearch === '') {
      callSearchReceiptDetail(filterList,  page, newPerPage )
    } else {
      callGetBarcodeApi(page, newPerPage)
    }
	};

  const [ tableList, setTableList ] = useState([])
  const [ filterList, setFilterList ] = useState([])

  const [ barcodeSearch, setBarcodeSearch ] = useState("")

  let [ showMessage, setShowMessage ] = useState(false)
  let [ showMessageContent, setShowMessageContent ] = useState('')

  let [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  let [ showMessageFullSearchContent, setShowMessageFullSearchContent ] = useState('')
  

  useEffect(() => {
    window.scrollTo(0, 0)

    getDefaultFromStorage()
  }, [])

  function gotoNew(){
    navigate("/receipt/new")
  }
  function gotoDetail( id ){
    navigate("/receipt/detail", {
      state : {
        id : id
      }
    })
  }
  
  function saveFilterToStorage( filterList ){
    let json = {
      page: "home",
      filter: filterList
    }
    localStorage.setItem("filter_orchid", JSON.stringify(json));
  }
  function getDefaultFromStorage(){
    let json = localStorage.getItem("filter_orchid")
    if(json === null){
      let defaultFilter = getDefaultFilter()
      setFilterList(defaultFilter)
      callSearchReceiptDetail(defaultFilter, page, perPage)
    }
    else{

      let obj = JSON.parse(json)
      if(obj.page === "home"){
        setFilterList(obj.filter)
        callSearchReceiptDetail(obj.filter, page, perPage)
      }
      else{
        let defaultFilter = getDefaultFilter()
        setFilterList(defaultFilter)
        callSearchReceiptDetail(defaultFilter, page, perPage)
      }
    }
  }


  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage() {
    setShowMessage(false)
  }



  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function submitModalMessageFullSearch( filter ){

    let tags = convertFilterToRequest(filter)
    initTagSearch( tags )
    saveFilterToStorage( tags )
    callSearchReceiptDetail( tags, page, perPage )

    hideModalMessage()


  }


  function initTagSearch( tags ){
    // let filter = {
    //   name: plantName.description,
    //   code: plantCode.description,
    //   familyMain: plantMainFamily.description,
    //   mainTask: mainTask,
    //   food: plantFood.description,
    //   customer: customer.id,
    //   employee: employee
    // }
    setFilterList([])
    setFilterList(tags)
  }

  function convertFilterToRequest(filter){

    let oldArray = new Array()
    oldArray.push({
      id: "plantName",
      value: filter.name
    })
    oldArray.push({
      id: "plantCode",
      value: filter.code
    })
    oldArray.push({
      id: "familyMain",
      value: filter.familyMain
    })
    oldArray.push({
      id: "workType",
      value: filter.workType
    })
    oldArray.push({
      id: "food",
      value: filter.food
    })
    oldArray.push({
      id: "customer",
      value: filter.customer
    })
    oldArray.push({
      id: "employee",
      value: filter.employee
    })
    oldArray.push({
      id: "mainTask",
      value: filter.mainTask
    })
    oldArray.push({
      id: "importStartDate",
      value: filter.importStartDate
    })
    oldArray.push({
      id: "importEndDate",
      value: filter.importEndDate
    })
    return oldArray
  }

  function getDefaultFilter(  ){
 
    let oldArray = new Array()
    
    oldArray.push({
      id: "plantName",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "plantCode",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "familyMain",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "workType",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "food",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "customer",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "employee",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "mainTask",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importStartDate",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importEndDate",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })

    return oldArray
  }

  const columns = [
    {
        name: 'รหัสใบงาน',
        selector: row => row.code,
        sortable: true,
    },
    {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'สายพันธุ์',
        selector: row => ( row.family_main !== null )? row.family_main.description : "",
        sortable: true,
    },
    {
        name: 'ชื่อคู่ค้า',
        selector: row => ( row.customer !== null )? row.customer.name : "",
        sortable: true,
    },
    {
        name: '',
        cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoDetail(row.receipt_id)}} id={row.receipt_id}>รายละเอียด</button>
    },
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

//api
function callGetApi( ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReceipts.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}
function callGetBarcodeApi( page, perPage){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        word: barcodeSearch,
        page: page,
        per_page: perPage,
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchReceiptsByCode.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}
function callSearchReceiptDetail( filter, page, perPage ){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: perPage,
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/searchReceiptDetail.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}



function validateGetApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined   ){
    let infos = (data.data.data)
    setTotalRowAll(data.data.total_all)
    setTableList(infos)
  }
} 

function validateGetBarcodeApi( data ){
  if(data.data !== undefined ){
    let infos = (data.data)
    let result = []
    if(infos.code !== undefined){
      result.push(infos)
    }
    setTableList(result)
  }
  else{
    MySwal.fire({
      icon: 'warning',
      title: <p>{ data.message }</p>,
      confirmButtonText: "ตกลง",
    })
  }
} 

function searchBarcode(){
  if( barcodeSearch === "" ){
    callSearchReceiptDetail(filterList, page, perPage )
  }
  else{
    callGetBarcodeApi(page, perPage)
  }
}

function removeTag( index ){

  let oldArray = [...filterList]

  setFilterList([])
  for(let i =0; i < oldArray.length; i++){
    if(index === i){
      oldArray[i].value.data.description = ''
      oldArray[i].value.data.id = ''
      oldArray[i].value.is_match_all = false
    }
  }
  setFilterList(oldArray)
  saveFilterToStorage(oldArray)
  callSearchReceiptDetail( oldArray, page, perPage )

}

function getTags(){
  return filterList
}

function enterSearch( e ){
  if(e.key === 'Enter'){
    searchBarcode()
  }
}
const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}

const callApiExportExcel = async () => {
  setIsShowProgress(true)
  const response = await exportFileExcel(
    process.env.REACT_APP_HOST_NEXTJS + '/excel/receipt', 
    'GET', 
    { 
        filter: JSON.stringify(filterList)
    },
    'รายชื่อใบงาน.xlsx')
  setIsShowProgress(false)
  return response;
}

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessage ? <ModalFilter page={"home"} default={filterList} show={showMessage} handleClose={hideModalMessage} handleSubmit={submitModalMessageFullSearch}  handleFullSearch={showModalMessageFullSearch} title={"แจ้งเตือน"} message={showMessageContent} button={"ลองอีกครั้ง"}  ></ModalFilter> : null
        }
        {
          showMessageFullSearch ? <ModalFullSearch show={showMessage} handleClose={hideModalMessageFullSearch}  title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">ใบงาน</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                    <input onKeyPress={enterSearch} value={barcodeSearch} onChange={(e)=>{ setBarcodeSearch(e.target.value) }} placeholder={"ค้นหาด้วย รหัสใบงาน"} className="w-72 border rounded-md px-1 py-2 content-end" type="text"  />
                    <button  onClick={()=>{searchBarcode()}}  className="bg-green-600 ml-3 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    <button  onClick={()=>{setShowMessage(true)}} className="ml-3 mt-3 lg:mt-0 border px-3 py-2 border-green-500 rounded-lg text-green-500 font-bold" >ค้นหาโดยละเอียด</button>
                  </div>
                </div>
              </div>
              
            

            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( item.value.data.description !== "" ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                          <button onClick={(e)=>{ removeTag(index) }} className="flex my-auto" ><span className="text-xs text-black font-bold ml-3">X</span></button>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

              <div className="w-full lg:w-1/2 mt-2  flex  flex-grow flex-wrap pl-3">
                  <div className="w-1/2   ">
                    <button 
                    onClick={callApiExportExcel}
                    className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>
                  </div>
                  <div className="w-1/2 justify-end flex  pl-3">
                    <button  onClick={gotoNew} className="border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >เพิ่มรายการใหม่</button>
                  </div>
              </div>
            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
              />
            </div>
          </div>  
        </div>  


        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default HomePage;