import { Suspense }  from 'react';
import  { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';

import LoginPage from '../src/pages/LoginPage'
import HomePage from '../src/pages/HomePage'
import BarcodePage from '../src/pages/BarcodePage'
import ReceiptNewPage from '../src/pages/ReceiptNewPage'
import ReceiptDetailPage from '../src/pages/ReceiptDetailPage'
import ReceiptEditPage from '../src/pages/ReceiptEditPage'


import ReceiptImportDetail from '../src/pages/ReceiptImportDetail'
import ReceiptRemoveDetail from '../src/pages/ReceiptRemoveDetail'
import ReceiptExportDetail from '../src/pages/ReceiptExportDetail'

import ImportPage from '../src/pages/ImportPage'
import ImportHistoryPage from '../src/pages/ImportHistoryPage'
import ImportHistoryDetailPage from '../src/pages/ImportHistoryDetailPage'



import RemovePage from '../src/pages/RemovePage'
import RemoveHistoryPage from '../src/pages/RemoveHistoryPage'
import RemoveHistoryGroupPage from '../src/pages/RemoveHistoryGroupPage'

import RemoveHistoryDetailPage from '../src/pages/RemoveHistoryDetailPage'


import ExportPage from '../src/pages/ExportPage'

import BarcodeWithEmployeePage from '../src/pages/BarcodeWithEmployeePage'

import CustomerPage from '../src/pages/CustomerPage'
import FoodPage from '../src/pages/FoodPage'
import MainPlantFamilyPage from '../src/pages/MainPlantFamilyPage'
import WorkTypePage from '../src/pages/WorkTypePage'
import EmployeePage from '../src/pages/EmployeePage'
import WorkMainTypePage from '../src/pages/WorkMainTypePage'


import ReportProductionPage from '../src/pages/ReportProductionPage'
import ReportStockPage from '../src/pages/ReportStockPage'
import ReportBottlePage from '../src/pages/ReportBottlePage'
import ReportRemovePage from '../src/pages/ReportRemovePage'
import ReportFailPlantPage from '../src/pages/ReportFailPlantPage'
import ReportRemoveAllPage from '../src/pages/ReportRemoveAllPage'

import ChangePasswordPage from '../src/pages/ChangePasswordPage'


function App() {
  return (
  <Suspense  fallback="loading">
    <BrowserRouter>
      <Routes>

        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/home" element={<HomePage />} />

        <Route exact path="/barcode" element={<BarcodePage />} />
        

        <Route exact path="/receipt/new" element={<ReceiptNewPage />} />
        <Route exact path="/receipt/edit" element={<ReceiptEditPage />} />
        <Route exact path="/receipt/detail" element={<ReceiptDetailPage />} />
        <Route exact path="/receipt/import" element={<ReceiptImportDetail />} />
        <Route exact path="/receipt/remove" element={<ReceiptRemoveDetail />} />
        <Route exact path="/receipt/export" element={<ReceiptExportDetail />} />

        <Route exact path="/import" element={<ImportPage />} />
        <Route exact path="/import/history" element={<ImportHistoryPage />} />
        <Route exact path="/import/history/detail" element={<ImportHistoryDetailPage />} />
        
        
        <Route exact path="/remove" element={<RemovePage />} />
        <Route exact path="/remove/history" element={<RemoveHistoryPage />} />
        <Route exact path="/remove/group" element={<RemoveHistoryGroupPage />} />
        <Route exact path="/remove/history/detail" element={<RemoveHistoryDetailPage />} />

        
        <Route exact path="/export" element={<ExportPage />} />

        <Route exact path="/barcode/employee" element={<BarcodeWithEmployeePage />} />

        <Route exact path="/customer" element={<CustomerPage />} />
        <Route exact path="/food" element={<FoodPage />} />
        <Route exact path="/mainfamily" element={<MainPlantFamilyPage />} />
        <Route exact path="/worktype" element={<WorkTypePage />} />
        <Route exact path="/workmaintype" element={<WorkMainTypePage />} />
        
        
        <Route exact path="/employee" element={<EmployeePage />} />    
        <Route exact path="/employee/password" element={<ChangePasswordPage />} />    
        

        <Route exact path="/report/production" element={<ReportProductionPage />} />
        <Route exact path="/report/stock" element={<ReportStockPage />} />
        <Route exact path="/report/bottle" element={<ReportBottlePage />} />
        <Route exact path="/report/remove" element={<ReportRemovePage />} />
        <Route exact path="/report/fail-plant" element={<ReportFailPlantPage />} />
        <Route exact path="/report/remove-all" element={<ReportRemoveAllPage />} />
        
      </Routes>
    </BrowserRouter>
  </Suspense>

  );
}

export default App;
