import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'

import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'
import { formatNumber } from '../utils/format-number'
import { exportFileExcel } from '../utils/export-file'

import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import ProgressWaiting from '../components/ProgressWaiting';
import ReactExport from "react-export-excel";
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ReportFailPlantPage() {
  
  const navigate = useNavigate();

  const [ summaryTable, setSummaryTable ] = useState(null)
  const [ tableList, setTableList ] = useState([])
  const [ filterList, setFilterList ] = useState([])

  const [ isShowProgress, setIsShowProgress ] = useState(false)
  let [ showMessage, setShowMessage ] = useState(false)
  let [ showMessageContent, setShowMessageContent ] = useState('')

  let [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  let [ showMessageFullSearchContent, setShowMessageFullSearchContent ] = useState('')
  

  useEffect(() => {
    window.scrollTo(0, 0)
    getDefaultFromStorage()
  }, [])


  function saveFilterToStorage( filterList ){
    let json = {
      page: "report_fail_plant",
      filter: filterList
    }
    localStorage.setItem("filter_orchid", JSON.stringify(json));
  }
  function getDefaultFromStorage(){
    let json = localStorage.getItem("filter_orchid")
    if(json === null){
      let defaultFilter = getDefaultFilter()
      setFilterList(defaultFilter)
      callGetSummaryApi(defaultFilter, page, perPage)
    }
    else{

      let obj = JSON.parse(json)
      if(obj.page === "report_fail_plant"){
        setFilterList(obj.filter)
        callGetSummaryApi(obj.filter, page, perPage)
      }
      else{
        let defaultFilter = getDefaultFilter()
        setFilterList(defaultFilter)
        callGetSummaryApi(defaultFilter, page, perPage)
      }
    }
  }




  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage() {
    setShowMessage(false)
  }



  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function submitModalMessageFullSearch( filter ){
    let tags = convertFilterToRequest(filter)
    initTagSearch( tags )
    saveFilterToStorage( tags )
    callGetSummaryApi( tags, page, perPage )

    hideModalMessage()


  }

  function initTagSearch( tags ){
    setFilterList([])
    setFilterList(tags)
  }


  function convertFilterToRequest(filter){

    let oldArray = new Array()
    oldArray.push({
      id: "plantName",
      value: filter.name
    })
    oldArray.push({
      id: "plantCode",
      value: filter.code
    })
    oldArray.push({
      id: "familyMain",
      value: filter.familyMain
    })
    oldArray.push({
      id: "workType",
      value: filter.workType
    })
    oldArray.push({
      id: "food",
      value: filter.food
    })
    oldArray.push({
      id: "customer",
      value: filter.customer
    })
    oldArray.push({
      id: "employee",
      value: filter.employee
    })
    oldArray.push({
      id: "mainTask",
      value: filter.mainTask
    })
    oldArray.push({
      id: "importStartDate",
      value: filter.importStartDate
    })
    oldArray.push({
      id: "importEndDate",
      value: filter.importEndDate
    })
    return oldArray
  }

  function getDefaultFilter(  ){
 
    let oldArray = new Array()
    
    oldArray.push({
      id: "plantName",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "plantCode",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "familyMain",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "workType",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "food",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "customer",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "employee",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "mainTask",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "importEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })

    return oldArray
  }

  function getMonthYearDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    return   moment().add(-1, 'days').format("YYYY-MM-DD")
  } 

  function getDateDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    let d = date.getDate()
    return   ( date.getFullYear())+"-"+(( m < 10 ) ?  "0"+m : m )+"-"+(( d < 10 ) ?  "0"+d : d )
  } 

  const columns = [
    {
        name: 'ชื่อ-นามสกุลพนักงาน',
        selector: row => `${row.member_name} ${row.member_surname}`,
        wrap : true,
        width: "160px"  
    },
    {
        name: 'จำนวนแตกหัก',
        selector: row => parseInt(row.remove_type_2),
        wrap : true,
        right: true,
        width: "150px"  
    },
    {
        name: 'จำนวนขึ้นรา',
        selector: row => parseInt(row.remove_type_1),
        wrap : true,
        right: true,
        width: "150px"  
    },
    {
        name: 'จำนวนทำทั้งหมด',
        selector: row => parseInt(row.total_import),
        wrap : true,
        right: true,
        width: "150px"  
    },
    {
        name: '% ขึ้นราเทียบกับจำนวนทำ',
        selector: row => formatNumber(parseFloat(row.persentage)),
        wrap : true,
        right: true,
        width: "150px"  
    }

    
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

//api
function callGetApi(summaryTable, filter,page, per_page ){
  setIsShowProgress(true)
  const requestOptions = {
    headers: new Headers({
      'content-type': 'application/json'
    }),
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: per_page
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_NEXTJS + '/report/plant-fail', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(summaryTable, data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}

function callGetSummaryApi( filter,page, per_page ){
  setIsShowProgress(true)
  const requestOptions = {
    headers: new Headers({
      'content-type': 'application/json'
    }),
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: per_page
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_NEXTJS + '/report/plant-fail-all', requestOptions)
  .then(response => response.json())
  .then(data => validateGetSummaryApi(data,  filter,page, per_page ), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}
function validateGetSummaryApi( data,  filter,page, per_page  ){
  setIsShowProgress(false)
  let d = null
  if(data !== undefined ){

    d =
      {
        member_name: data.member_name,
        member_surname: '',
        remove_type_2: data.remove_type_2,
        remove_type_1: data.remove_type_1,
        total_import: data.total_import,
        persentage: data.persentage
      }
    
    setSummaryTable(d)
    setTotalRowAll(parseInt(data.total))
  }
  callGetApi(d, filter,page, per_page )
}

function validateGetApi(summaryTable, data ){
  setIsShowProgress(false)
  if(data !== undefined ){
    let infos = (data)
    let d = (infos.data)
    const prev = []
    prev.push(summaryTable, ...d)
    console.log(prev);
    setTableList(prev)
    // setTotalRowAll(parseInt(infos.total))
  }
} 



function removeTag( index ){

  let oldArray = [...filterList]

  setFilterList([])
  for(let i =0; i < oldArray.length; i++){
    if(index === i){
      oldArray[i].value.data.description = ''
      oldArray[i].value.data.id = ''
      oldArray[i].value.is_match_all = false
      
    }
  }
  setFilterList(oldArray)
  saveFilterToStorage(oldArray)
  callGetSummaryApi( oldArray, page, perPage )

}

function getTags(){
  return filterList
}
const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}

const callApiExportExcel = async () => {
  setIsShowProgress(true)
  const response = await exportFileExcel(
    process.env.REACT_APP_HOST_NEXTJS + '/excel/report-fail', 
    'GET', 
    { 
        filter: JSON.stringify(filterList)
    },
    'รายงาน เนื้อเยื่อกล้วยไม้เสียหาย.xlsx')
  setIsShowProgress(false)
  return response;
}


const [ page, setPage ] = useState(1)
const [ perPage, setPerPage ] = useState(10)
const [ totalRowAll, setTotalRowAll ] = useState(10)
const handlePageChange = page => {
  setPage(page);
  // call
  callGetApi(summaryTable, filterList,  page, perPage )
};

const handlePerRowsChange = async (newPerPage, page) => {
  setPage(page)
  setPerPage(newPerPage)
  callGetApi(summaryTable, filterList,  page, newPerPage )
  
};

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessage ? <ModalFilter page={"report-plant-fail"}  default={filterList} show={showMessage} handleClose={hideModalMessage} handleSubmit={submitModalMessageFullSearch}  handleFullSearch={showModalMessageFullSearch} title={"แจ้งเตือน"} message={showMessageContent} button={"ลองอีกครั้ง"}  ></ModalFilter> : null
        }
        {
          showMessageFullSearch ? <ModalFullSearch show={showMessage} handleClose={hideModalMessageFullSearch}  title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">รายงาน เนื้อเยื่อกล้วยไม้เสียหาย</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                    <button  onClick={()=>{setShowMessage(true)}} className="ml-3 mt-3 lg:mt-0 border px-3 py-2 border-green-500 rounded-lg text-green-500 font-bold" >ค้นหาโดยละเอียด</button>
                  </div>
                </div>
              </div>
              
            

            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( item.value.data.description !== "" ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                          <button onClick={(e)=>{ removeTag(index) }} className="flex my-auto" ><span className="text-xs text-black font-bold ml-3">X</span></button>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

      
            </div>

            <div className="w-full  mt-5  ">
            <button 
            onClick={callApiExportExcel}
            className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>
            {/* <ExcelFile filename={"รายงานเนื้อเยื่อกล้วยไม้เสียหาย"} element={<button className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>}>
  
                <ExcelSheet data={tableList} name="Report">
                    <ExcelColumn label="ชื่อ-นามสกุล พนักงาน"  value={(col) => `${col.member_name} ${col.member_surname}` } />
                    <ExcelColumn label="จำนวนขึ้นรา" value={(col) => parseInt(col.remove_type_1) } />
                    <ExcelColumn label="จำนวนแตกหัก" value={(col) => parseInt(col.remove_type_2) } />
                    <ExcelColumn label="จำนวนทำทั้งหมด" value={(col) => parseInt(col.total_import) }/>
                    <ExcelColumn label="% ขึ้นราเทียบกับจำนวนทำ" value={(col) => parseFloat(formatNumber(parseFloat(col.persentage))) }/> */}
                    {/* <ExcelColumn label="export" value="export"/> */}
                    {/* 
                    <ExcelColumn label="customer_name" value="customer_name"/> */}

                    
                {/* </ExcelSheet>
            </ExcelFile> */}
            </div>
            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
              />
            </div>
          </div>  
        </div>  
        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default ReportFailPlantPage;