import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

import SlidingMenu from '../components/SlidingMenu'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { formatDate } from '../utils/format-date-time'

import SearchUI from '../components/SearchUI'

import ModalFilter from '../components/ModalFilter'
import ModalFullSearch from '../components/ModalFullSearch'

import iconSearch  from '../../src/ic_search.png'

import DataTable from 'react-data-table-component';

import StoredLocalConstant from '../constants/StoredLocalConstant.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProgressWaiting from '../components/ProgressWaiting';
import moment from 'moment';
function RemoveHistoryGroupPage() {
  
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ tableList, setTableList ] = useState([])
  const [ filterList, setFilterList ] = useState([])

  const [ barcodeSearch, setBarcodeSearch ] = useState("")

  let [ showMessage, setShowMessage ] = useState(false)
  let [ showMessageContent, setShowMessageContent ] = useState('')

  let [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  let [ showMessageFullSearchContent, setShowMessageFullSearchContent ] = useState('')
  

  useEffect(() => {
    window.scrollTo(0, 0)
    getDefaultFromStorage()
  }, [])

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)

  const handlePageChange = page => {
		setPage(page);
    // call
    callSearchReceiptDetail(filterList, page, perPage )
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    callSearchReceiptDetail(filterList,  page, newPerPage )
	};

  function saveFilterToStorage( filterList ){
    let json = {
      page: "report_history_group",
      filter: filterList
    }
    localStorage.setItem("filter_orchid", JSON.stringify(json));
  }
  function getDefaultFromStorage(){
    let json = localStorage.getItem("filter_orchid")
    if(json === null){
      let defaultFilter = getDefaultFilter()
      setFilterList(defaultFilter)
      callSearchReceiptDetail(defaultFilter, page, perPage)
    }
    else{

      let obj = JSON.parse(json)
      if(obj.page === "report_history_group"){
        setFilterList(obj.filter)
        callSearchReceiptDetail(obj.filter, page, perPage)
      }
      else{
        let defaultFilter = getDefaultFilter()
        setFilterList(defaultFilter)
        callSearchReceiptDetail(defaultFilter, page, perPage)
      }
    }
  }
  function gotoDetail( data ){
    console.log('gotoDetail:data')
    console.log(data)
    console.log('gotoDetail:filterList')
    console.log(filterList)
    navigate("/remove/history", {
      state : {
        filter : data,
        filter_all : filterList
      }
    })
  }
  

  function showModalMessage() {
    setShowMessage(true)
  }
  function hideModalMessage() {
    setShowMessage(false)
  }



  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function submitModalMessageFullSearch( filter ){
    let tags = convertFilterToRequest(filter)
    initTagSearch( tags )
    saveFilterToStorage( tags )
    callSearchReceiptDetail( tags, page, perPage )

    hideModalMessage()


  }

  function initTagSearch( tags ){
    setFilterList([])
    setFilterList(tags)
  }


  function convertFilterToRequest(filter){

    let oldArray = new Array()
    oldArray.push({
      id: "plantName",
      value: filter.name
    })
    oldArray.push({
      id: "plantCode",
      value: filter.code
    })
    oldArray.push({
      id: "familyMain",
      value: filter.familyMain
    })
    oldArray.push({
      id: "workType",
      value: filter.workType
    })
    oldArray.push({
      id: "food",
      value: filter.food
    })
    oldArray.push({
      id: "customer",
      value: filter.customer
    })
    oldArray.push({
      id: "employee",
      value: filter.employee
    })
    oldArray.push({
      id: "mainTask",
      value: filter.mainTask
    })
    oldArray.push({
      id: "importStartDate",
      value: filter.importStartDate
    })
    oldArray.push({
      id: "importEndDate",
      value: filter.importEndDate
    })
    oldArray.push({
      id: "reason",
      value: filter.reason
    })

    oldArray.push({
      id: "exportStartDate",
      value: filter.importStartDate
    })
    oldArray.push({
      id: "exportEndDate",
      value: filter.importEndDate
    })
    return oldArray
  }

  function getDefaultFilter(  ){
 
    let oldArray = new Array()
    
    oldArray.push({
      id: "plantName",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "plantCode",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "familyMain",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "workType",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "food",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "customer",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "employee",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "mainTask",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : false
      }
    })
    oldArray.push({
      id: "importStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "importEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "reason",
      value: {
        data : {
          id: "",
          description : ""
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "exportStartDate",
      value: {
        data : {
          id: "",
          description : getMonthYearDefault()
        },
        is_match_all : true
      }
    })
    oldArray.push({
      id: "exportEndDate",
      value: {
        data : {
          id: "",
          description : getDateDefault()
        },
        is_match_all : true
      }
    })

    return oldArray
  }

  function getMonthYearDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    return   moment().add(-1, 'days').format("YYYY-MM-DD")
  } 
  function getDateDefault(){
    let date = new Date()
    let m = date.getMonth()+1
    let d = date.getDate()
    return   ( date.getFullYear())+"-"+(( m < 10 ) ?  "0"+m : m )+"-"+(( d < 10 ) ?  "0"+d : d )
  } 


  const columns = [
    {
      name: 'วัน/เดือน/ปี ที่นำออก',
      selector: row => formatDate(row.remove_date),
      sortable: true,
      wrap : true
    },
    {
        name: 'จำนวนนำออก',
        selector: row => row.total_remove,
        sortable: true,
        wrap : true,
        right: true
    },
    {
      name: 'ชื่อ-นามสกุลพนักงานนำออก',
      selector: row => `${row.member_name} ${row.member_surname}`,
      sortable: true,
      wrap : true
    },
    {
        name: 'สาเหตุ',
        selector: row => row.remove_type,
        sortable: true,
        wrap : true,
        right: true
    },
    {
        name: 'ครั้งที่',
        selector: row => row.time_per_day,
        sortable: true,
        wrap : true,
        right: true
    },
    {
        name: '',
        cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={()=>{gotoDetail(row)}} id={row.receipt_id}>รายละเอียด</button>
    },
];


function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

//api
function callGetApi( ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReportHistoryImport.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });
  
}
function callGetBarcodeApi( ){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        barcode: barcodeSearch
      }
    )
  };

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getLogPlantImportByBarcode.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetBarcodeApi(data), (error) => {
    if (error) {
      // handle error here
      setIsShowProgress(false)
    }
  });
  
}
function callSearchReceiptDetail( filter , page, perPage){
  setIsShowProgress(true)
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
        filter: JSON.stringify(filter),
        page: page,
        per_page: perPage
      }
    )
  };

  console.log(filter)

  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReportHistoryRemoveGroup.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetApi(data), (error) => {
    if (error) {
      // handle error here
      setIsShowProgress(false)
    }
  });
  
}



function validateGetApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined   ){
    let infos = (data.data.data)
    setTableList(infos)
    setTotalRowAll(data.data.total_all)
  }
} 

function validateGetBarcodeApi( data ){
  setIsShowProgress(false)
  if(data.data !== undefined ){
    let infos = (data.data)
    let result = []
    if(infos.code !== undefined){
      result.push(infos)
    }
    setTableList(result)
    setBarcodeSearch("")
  }
  else{
    MySwal.fire({
      icon: 'warning',
      title: <p>{ data.message }</p>,
      confirmButtonText: "ตกลง",
    })
  }
} 

function searchBarcode(){
  if( barcodeSearch === "" ){
    callSearchReceiptDetail( filterList, page, perPage )
  }
  else{
    callGetBarcodeApi()
  }
}

function removeTag( index ){

  let oldArray = [...filterList]

  setFilterList([])
  for(let i =0; i < oldArray.length; i++){
    if(index === i){
      oldArray[i].value.data.description = ''
      oldArray[i].value.data.id = ''
      oldArray[i].value.is_match_all = false
      
    }
  }
  setFilterList(oldArray)
  saveFilterToStorage(oldArray)
  callSearchReceiptDetail( oldArray, page, perPage )

}

function getTags(){
  return filterList
}

function enterSearch( e ){
  if(e.key === 'Enter'){
    searchBarcode()
  }
}
const [ isShowMenu, setIsShowMenu ] = useState(1)
function onChangeStateMenu(isShowMenu) {
  setIsShowMenu(isShowMenu )
}


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
    {
          showMessage ? <ModalFilter page={"report_history_group"}  default={filterList} show={showMessage} handleClose={hideModalMessage} handleSubmit={submitModalMessageFullSearch}  handleFullSearch={showModalMessageFullSearch} title={"แจ้งเตือน"} message={showMessageContent} button={"ลองอีกครั้ง"}  ></ModalFilter> : null
        }
        {
          showMessageFullSearch ? <ModalFullSearch show={showMessage} handleClose={hideModalMessageFullSearch}  title={"แจ้งเตือน"} message={showMessageFullSearchContent} button={"ลองอีกครั้ง"}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">ประวัติการนำออก แยกตามครั้ง</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className="w-full flex  flex-wrap">

                <div className="flex-grow flex-wrap ">
                  <div className="flex flex-wrap ">
                
                    <button  onClick={()=>{setShowMessage(true)}} className="ml-3 mt-3 lg:mt-0 border px-3 py-2 border-green-500 rounded-lg text-green-500 font-bold" >ค้นหาโดยละเอียด</button>
                  </div>
                </div>
              </div>
              
            

            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( item.value.data.description !== "" ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                          <button onClick={(e)=>{ removeTag(index) }} className="flex my-auto" ><span className="text-xs text-black font-bold ml-3">X</span></button>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

            </div>


            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
              />
            </div>
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default RemoveHistoryGroupPage;