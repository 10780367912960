import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import { formatDate } from '../utils/format-date-time'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DataTable from 'react-data-table-component';
import iconHome from '../../src/ic_home.png'
import iconSearch  from '../../src/ic_search.png'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ModalEditLogImport from '../components/ModalEditLogImport';
import ModalFullSearch from '../components/ModalFullSearch';

import DatePicker from 'react-date-picker';

import ReactExport from "react-export-excel";

import ProgressWaiting from '../components/ProgressWaiting';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ImportHistoryDetailPage() {
  
  const navigate = useNavigate();
  const location = useLocation()
  const MySwal = withReactContent(Swal)
  const [ isShowProgress, setIsShowProgress ] = useState(false)

  const [ filterList, setFilterList ] = useState([])
  const [ barcode, setBarcode ] = useState('')
  
  const [ filter, setFilter ] = useState(location.state.filter)
  const [ importDate, setImportDate ] = useState(location.state.filter.import_date)
  const [ receiptCode, setReceiptCode ] = useState(location.state.receipt_code)
  const [ mainTaskId, setMainTaskId ] = useState(location.state.main_task)
  const [ workType, setWorkType ] = useState(location.state.work_type)
  const [ food, setFood ] = useState(location.state.food)
  const [ customer, setCustomer ] = useState(location.state.customer)
  const [ employee, setEmployee ] = useState(location.state.employee)
  const [ mainWorkType, setMainWorkType ] = useState((location.state.filter.work_type))
  const [ mainWorkTypeId, setMainWorkTypeId ] = useState(location.state.filter.main_work_type_id)
  const [ itemMainWorkTypeList, setItemMainWorkTypeList ] = useState([])

  const [ isShowMenuAdmin, setIsShowMenuAdmin ] = useState(false)


  const [ tableList, setTableList ] = useState([])
  
  const columns = [
    {
      name: 'Barcode',
      selector: row => row.barcode,
      sortable: true,
    },
    {
      name: 'วัน/เดือน/ปี ที่นำเข้า',
      selector: row => formatDate(row.import_date),
      sortable: true,
      wrap : true
    },
    // {
    //   name: 'ครั้งที่',
    //   selector: row => row.time_per_day,
    //   sortable: true,
    //   wrap : true
    // },
    {
        name: 'รหัสใบงาน',
        selector: row => row.receipt_code,
        sortable: true,
    },
    {
        name: 'ชื่อพันธุ์ไม้',
        selector: row => row.receipt_name,
        sortable: true,
    },
    {
        name: 'สายพันธุ์',
        selector: row => ( row.plant_family_main !== null )? row.plant_family_main : "",
        sortable: true,
    },
    {
        name: 'ประเภทงานหลัก',
        selector: row => row.main_work_type,
        sortable: true,
        wrap : true
    },
    {
        name: 'ประเภทงาน',
        selector: row => row.work_type,
        sortable: true,
        wrap : true
    },
    {
        name: 'อาหารวุ้น',
        selector: row => row.food,
        sortable: true,
        wrap : true
    },
    {
        name: 'ชื่อคู่ค้า',
        selector: row => ( row.customer_name !== null )? row.customer_name : "",
        sortable: true,
    },
    {
      name: 'ชื่อ-นามสกุลพนักงาน',
      selector: row => `${row.member_name} ${row.member_surname}`,
      sortable: true,
      wrap : true
    },
    {
      name: 'การนำออก',
      selector: row => row.remove_type,
      sortable: true,
    },
    {
      name: '',
      cell:(row)=> isShowMenuAdmin ? <>  <button onClick={(e)=>{ showModalMessageFullSearch(row.barcode) }} class="font-bold bg-yellow-500 text-white px-2 py-1 ml-1 rounded-md" >แก้ไข</button></> : null,
      wrap : true
    },
    
    // {
    //     name: '',
    //     cell:(row)=><button  class="bg-gray-600 rounded-lg px-2 py-1 font-bold text-white" onClick={gotoDetail} id={row.ID}>ลบ</button>
    // },

];

function checkAdminGrant() {
  const grant = localStorage.getItem(StoredLocalConstant.GRANT) 
  if(grant && grant !== null && grant !== '' && grant === '3'){
    setIsShowMenuAdmin(true)
  } else {
    setIsShowMenuAdmin(false)
  }
  
}

useEffect(() => {
  console.log('STORAGE :');
  console.log(location);
  window.scrollTo(0, 0)
  checkAdminGrant()
  initTagSearch()
}, [])

function getToken(){
  return localStorage.getItem(StoredLocalConstant.TOKEN) 
}

function initTagSearch(  ){


  let oldArray = new Array()
  
  setFilterList([])
  oldArray.push({
    value : {
      data : {
        id: "",
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.receipt_code
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.work_type_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.food
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.customer_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.member_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: filter.main_work_type_id,
        description: ""
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.import_date
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.import_date
      },
      is_match_all : true
    }
  })
  oldArray.push({
    value : {
      data : {
        id: "",
        description: filter.time_per_day
      },
      is_match_all : true
    }
  })


  setFilterList(oldArray)

  callWorkTypeApi(oldArray)
}




function callWorkTypeApi(oldArray ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        token: getToken(),
      }
    )
  };
  fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getSourceMainWorkTypes.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetWorkTypeApi(data, oldArray), (error) => {
    if (error) {
      // handle error here
    }
  });
} 

function validateGetWorkTypeApi( data, oldArray ){
  let result = []
  if(data.data !== undefined ){
    let infos = (data.data)
    for(let i = 0; i < infos.length; i++){
      let info  = infos[i]

      result.push({
        id: info.id,
        description: info.description
      })
    }
  }
  if(result.length > 0){
    //setMainWorkTypeId(result[0].id)
  }
  setItemMainWorkTypeList(result)


  callSearchReceiptDetail( oldArray )
}



  //api
  function callSearchReceiptDetail( filter ){
    setIsShowProgress(true)
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          filter: JSON.stringify(filter)
        }
      )
    };
  
    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReportHistoryImportDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
        setIsShowProgress(false)
      }
    });
    
  }
  function validateGetApi( data ){
    setIsShowProgress(false)
    if(data.data !== undefined ){
      let infos = (data.data)
      setTableList(infos)
    }
  } 

  function gotoDetail( id ){
    navigate("/receipt/detail", {
      state : {
        id : id
      }
    })
  }




  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }


  function toStringDate( date ){

    if(date !== "" && date !== null){

      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }

  function getTags(){
    return filterList
  }
  
  const [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)
  const [ modelBarcode, setModelBarcode ] = useState(0)
  
  function showModalMessageFullSearch( barcode ) {
    setModelBarcode(barcode)
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch(isRefresh) {
    setShowMessageFullSearch(false)
    if(isRefresh) {
      callSearchReceiptDetail(filterList)
    }
  }
  function showFilterSearch( id ){

    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }



  const [ showMessageFullSearchValue, setShowMessageFullSearchValue ] = useState(false)
  const [ modalFullSearchValue, setModalFullSearchValue ] = useState(0)
  function showModalMessageFullSearchValue() {
    setShowMessageFullSearchValue(true)
  }
  function hideModalMessageFullSearchValue() {
    setShowMessageFullSearchValue(false)
  }
  function showFilterSearchValue( id ){

    setModalFullSearchValue( id )
    showModalMessageFullSearchValue()
  }

  const [ plantCode, setPlantCode ] = useState({
    id: location.state.filter.receipt_code,
    description:location.state.filter.receipt_code,
  })
  const [ workTypeId, setWorkTypeId ] = useState({
    id: location.state.filter.work_type_id,
    description:location.state.filter.work_type,
  })
  const [ plantFood, setPlantFood ] = useState({
    id: location.state.filter.food_id,
    description:location.state.filter.food,
  })
  const [ memberId, setMemberId ] = useState("")
  const [ itemEmployeeList, setItemEmployeeList ] = useState([])

  function handlerSearchValue(id, description){
    
    if(modalFullSearchValue === 3){
      setPlantFood({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchValue === 4){
      setPlantCode({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchValue === 5){
      setWorkTypeId({
        id: id,
        description:description
      })
    }

    hideModalMessageFullSearchValue()
  }

  function getBarcodes( tables ){
    let items = []
    for( let i = 0; i < tables.length; i++){
      items.push(tables[i].barcode)
    }
    return items
  }

  function validate(){
    // if(  customerCode === "" || customerName === ""){
    //   return false
    // }
    return true
  }
  function save(){
    if(validate()){
      callUpdateApi(  )
      
    }
    else{
  
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }


  function callUpdateApi(  ){

    const body = {
      token: getToken(),
      barcodes: getBarcodes( tableList ),
      reciept_code: plantCode.description,
      import_date: importDate,
      main_work_type_id: mainWorkTypeId,
      work_type_id: workTypeId.id,
      food_id: plantFood.id
    }
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-import/all', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateLog(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  }
  function validateUpdateLog( data ){
    navigate('/import/history' , {replace: true })
  }

  function submitNewBarcode(){
    if( barcode !== '') {
      callInsertApi();
    }
  }

  function callInsertApi( ){
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          token: getToken(),
          "import_date" : (importDate),
          "barcode" : barcode,
          "food_description" : plantFood.description,
          "work_type_description" : workTypeId.description,
          "main_work_type_id" : mainWorkTypeId,
          "reciept_code" : plantCode.description,
          "time_per_day": filter.time_per_day
        }
      )
    };
    fetch(process.env.REACT_APP_HOST_NEXTJS + '/log-plant-import', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  } 

  function validateInsertApi( data ){

    if(data.data !== undefined ){
      setBarcode('')
      callSearchReceiptDetail( filterList )
    }
    else {
      
      MySwal.fire({
        icon: 'warning',
        title: <p>{ data.message }</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }
  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >
        {
          showMessageFullSearch ? <ModalEditLogImport default={modelBarcode} show={showMessageFullSearch} handleClose={hideModalMessageFullSearch} title={"แจ้งเตือน"} message={''} button={"ลองอีกครั้ง"}  ></ModalEditLogImport> : null
        }
        {
            showMessageFullSearchValue ? <ModalFullSearch type_id={modalFullSearchValue} show={showMessageFullSearchValue} handleClose={hideModalMessageFullSearchValue} handleSelected={handlerSearchValue}  ></ModalFullSearch> : null
        }
        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full flex flex-wrap pl-12 py-5">
              <button onClick={ (e)=>{ navigate("/import/history")} }><img src={iconHome} alt="" className="h-4 my-auto " /></button>
              <span className="my-auto ml-3 text-sm">หน้าประวัตินำเข้า</span>
            </div> 

            <div className="w-full ">
              <span className="font-bold text-3xl">รายละเอียดจำนวนนำเข้าทั้งหมด</span>
            </div>

            <div className="w-full flex flex-wrap mt-5">
              <div className=" flex flex-wrap mt-2">
                {
                  getTags().map(function(item, index){
                    if( (item.value.data.description) !== undefined && ( (item.value.data.description) !== "" && (item.value.data.description).trim() !== ""  )  ){
                      let prefix = (item.value.is_match_all)? "" : "%"
                      return (
                        <div key={index} className="flex flex-wrap bg-gray-300 rounded-full px-3 py-2 mr-4 mt-2">
                          <span className="flex my-auto text-xs">{prefix}{item.value.data.description}{prefix}</span>
                        </div>
                      )
                    }
                  })
                }
        
              
              </div>

      
            </div>

            <div className="w-full  mt-5  ">
            <ExcelFile filename={"รายงาน นำเข้า"} element={<button className={'text-white border px-4 py-2 bg-green-500 rounded-lg'}>Download Excel</button>}>
  
                <ExcelSheet data={tableList} name="Report">
                    <ExcelColumn label="Barcode"  value="barcode" />
                    <ExcelColumn label="วัน/เดือน/ปี ที่นำเข้า" value="import_date"/>
                    <ExcelColumn label="รหัสใบงาน" value="receipt_code"/>
                    <ExcelColumn label="ชื่อพันธุ์ไม้" value="receipt_name"/>
                    <ExcelColumn label="สายพันธุ์" value="plant_family_main"/>
                    <ExcelColumn label="ประเภทงานหลัก" value="main_work_type"/>
                    <ExcelColumn label="ประเภทงาน" value="work_type"/>
                    <ExcelColumn label="อาหารวุ้น" value="food"/>
                    <ExcelColumn label="ชื่อคู่ค้า" value="customer_name"/>
                    <ExcelColumn label="ชื่อ-นามสกุลพนักงาน" value={(col) => `${col.member_name} ${col.member_surname}` }/>
                    <ExcelColumn label="การนำออก" value="remove_type"/>
            
                </ExcelSheet>
            </ExcelFile>
            </div>


        
            <div className="w-full bg-gray-200  flex flex-wrap mt-5">


              <div className=" w-full lg:w-1/2 flex  mt-5 flex-wrap px-3">
                <div className="w-full flex flex-wrap bg-white py-6 px-6  rounded-lg">
                  <div className="w-full flex flex-wrap ">
                    <span className="font-bold text-lg">แก้ไขข้อมูลรายการทั้งหมด</span>
                  </div>
                  <span className="w-full flex  text-black text-md text-center">
                    รหัสใบงาน
                  </span>

                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input 
                      value={ plantCode.description } 
                      disabled  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button onClick={(e)=>{showFilterSearchValue(4)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-black text-md text-center">
                    วันที่นำเข้า
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className=" w-full flex flex-wrap">
      
                      <DatePicker format="dd/MM/y"  value={ toDate(importDate) } onChange={ (value)=>{ setImportDate(toStringDate(value)) } } className='w-full border rounded-md px-1 py-2 content-en' />
                    </div>
                  </div> 

                  <span className="w-full flex mt-2 text-black text-md text-center">
                    ประเภทงานหลัก
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className=" w-full flex flex-wrap">
                      <select value={ mainWorkTypeId } onChange={ (e)=>{ setMainWorkTypeId(e.target.value) } }  className="w-full border rounded-md px-1 py-2 content-end">
                        {
                          itemMainWorkTypeList.map(function(item, index){
                            return <option value={item.id}>{item.description}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-black text-md text-center">
                    ประเภทงาน
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input disabled value={  workTypeId.description }   className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button  onClick={(e)=>{showFilterSearchValue(5)}} className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-black text-md text-center">
                    อาหารวุ้น
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input  disabled value={ plantFood.description }    className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button  onClick={(e)=>{showFilterSearchValue(3)}} className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>
                  <div className=" w-full flex flex-wrap mt-2">
                        <button onClick={save} className="w-full mt-3   border px-12 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึกข้อมูล</button>
                  </div>
                </div>  
              </div>

              <div className=" w-full lg:w-1/2   mt-5 flex-wrap px-3">
                <div className="w-full  flex-wrap bg-white py-6 px-6  rounded-lg">
                  <div className="w-full  flex-wrap ">
                    <span className="font-bold text-lg">เพิ่มข้อมูล Barcode</span>
                  </div>
                  <span className="w-full   text-black text-md">
                    Barcode
                  </span>

                  <div className=" w-full  flex-wrap mt-1">
                    <div className=" w-full flex-wrap">
                      <input 
                      value={ barcode } 
                      onChange= { (e) => { setBarcode(e.target.value)} }
                      onKeyPress={(e)=>{ if(e.key === 'Enter') { submitNewBarcode()  }}}
                      className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className=" w-full flex-wrap">
                      <button onClick={submitNewBarcode}  
                      className={
                        ((barcode === '') ? ' bg-gray-200 border-gray-200 ' : ' bg-green-500  border-green-500 ' )+
                        "w-full mt-3 border px-3 py-2 text-white rounded-lg  font-bold"} >เพิ่มรายการใหม่</button>  
                    </div>
                  </div> 
                </div>
              </div>    

            </div>



            <div className="w-full  mt-5   bg-white">
              <DataTable
              columns={columns}
              data={tableList}
              pagination
              />
            </div>
 
          </div>  
        </div>  

        {
          isShowProgress ?
          <ProgressWaiting />
          :
          null
        }
      </div>
  )
}

export default ImportHistoryDetailPage;