import React, { useState , useEffect} from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'

import SlidingMenu from '../../src/components/SlidingMenu'
import Header from '../../src/components/Header'
import SubHeader from '../../src/components/SubHeader'

import SearchUI from '../../src/components/SearchUI'

import ModalFilter from '../../src/components/ModalFilter'
import ModalFullSearch from '../../src/components/ModalFullSearch'

import iconSearch  from '../../src/ic_search.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StoredLocalConstant from '../../src/constants/StoredLocalConstant.js'
import DatePicker from 'react-date-picker';

function ReceiptEditPage() {
  
  const navigate = useNavigate();
  const location = useLocation()
  const MySwal = withReactContent(Swal)



  const [ receiptId, setReceiptId ] = useState(location.state.id)
  const [ createDate, setCreateDate ] = useState("")
  const [ plantCode, setPlantCode ] = useState("")
  const [ plantName, setPlantName ] = useState({id:"", description:""})
  const [ customer, setCustomer ] = useState({id:"", description:""})
  const [ plantMainFamily, setPlantMainFamily ] = useState({id:"", description:""})
  const [ plantNumberOrder, setPlantNumberOrder ] = useState("")

  const [ showMessageFullSearch, setShowMessageFullSearch ] = useState(false)
  const [ modalFullSearchId, setModalFullSearchId ] = useState(0)
  function showModalMessageFullSearch() {
    setShowMessageFullSearch(true)
  }
  function hideModalMessageFullSearch() {
    setShowMessageFullSearch(false)
  }
  function showFilterSearch( id ){

    setModalFullSearchId( id )
    showModalMessageFullSearch()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    callGetApi()
  }, [])

  function handlerSearchReceipt(id, description){

    if(modalFullSearchId === 0){
      setPlantName({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 1){
      setCustomer({
        id: id,
        description:description
      })
    }
    else if(modalFullSearchId === 2){
      setPlantMainFamily({
        id: id,
        description:description
      })
    }
  
  
 
    hideModalMessageFullSearch()
  }

  function save(){
    if(validate()){
      callInsertApi(true)
    }
    else{
      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }
  function gotoImport(){
    if(validate()){
      callInsertApi(false)
    }
    else{

      MySwal.fire({
        icon: 'warning',
        title: <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>,
        confirmButtonText: "ตกลง",
      })
    }
  }

  function validate(){
    if( plantCode === "" || plantName.description === "" || plantMainFamily.description === "" || createDate === "" || customer.description === "" ){
      return false
    }
    return true
  }
  function getToken(){
    return localStorage.getItem(StoredLocalConstant.TOKEN) 
  }
  


  //api
  function callGetApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          token: getToken(),
          id: receiptId
        }
      )
    };

    fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/getReceiptDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
    
  }

  function validateGetApi( data ){
    if(data.data !== undefined ){
      let infos = (data.data)
      setPlantName({
        id: "",
        description:infos.name
      })
      setPlantCode(infos.code)
      setPlantNumberOrder( (infos.num_order === '-1')? '' : infos.num_order )
      setCustomer({
        id: infos.customer.customer_id,
        description:infos.customer.name
      })
      setPlantMainFamily({
        id: infos.family_main.id,
        description:infos.family_main.description
      })
      setCreateDate(infos.create_at)
    }
  } 

    function callInsertApi( is_home ){
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          { 
            token: getToken(),
            receipt_id : receiptId,
            date : createDate,
            code : plantCode,
            name : plantName.description,
            customer_id : customer.id,
            plant_family_main : plantMainFamily.description,
            plant_family_secondary : "",
            num_order : plantNumberOrder
          }
        )
      };
      fetch(process.env.REACT_APP_HOST_PHP + '/service/api/web/v1/updateReceipt.php', requestOptions)
      .then(response => response.json())
      .then(data => validateSearchInsertApi(data), (error) => {
        if (error) {
          // handle error here
        }
      });
    } 
    function validateSearchInsertApi( data ){
      if(data.data !== undefined ){
          navigate("/receipt/detail", {
            state :{
              id : receiptId
            },
            replace: true
          })
        
      }
      else{
        MySwal.fire({
          icon: 'warning',
          title: <p>{data.message}</p>,
          confirmButtonText: "ตกลง",
        })
      }
    } 


  function toDate( dateStr ){
    if(dateStr !== "" && dateStr !== null){
      var date = new Date(dateStr)
      return date
    }
    return null
  }

  function toStringDate( date ){
    if(date !== "" && date !== null){

      const str =  date.getFullYear()+"-"+((date.getMonth()+1) < 10? ("0"+(date.getMonth()+1)) : (date.getMonth()+1)+"")+"-"+(date.getDate() < 10? "0"+date.getDate() : date.getDate()+"")
      return str
    }
    return null
  }

  const [ isShowMenu, setIsShowMenu ] = useState(1)
  function onChangeStateMenu(isShowMenu) {
    setIsShowMenu(isShowMenu )
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sans w-full" >

        {
          showMessageFullSearch ? <ModalFullSearch type_id={modalFullSearchId} show={showMessageFullSearch} handleClose={hideModalMessageFullSearch} handleSelected={handlerSearchReceipt}  ></ModalFullSearch> : null
        }

        <div className={((isShowMenu == 1)? ' lg:flex  ' : ' ' )+" w-2/12   bg-white border-r  hidden  flex-wrap px-1"}>
          <SlidingMenu />
        </div>

        <div className={ ((isShowMenu == 1)? ' lg:w-10/12 ' : ' w-full ' ) +" w-full  bg-white" }>
          <Header  onChangeStateMenu={onChangeStateMenu} />
          <div className="w-full p-5 bg-gray-200 ">
            <div className="w-full ">
              <span className="font-bold text-3xl">แก้ไขใบงาน</span>
            </div>


            <div className="flex flex-wrapfont-sarabun w-full" >
              <div className="mx-auto w-10/12 lg:w-8/12 xl:w-6/12  flex flex-wrap bg-white rounded-xl  my-12">
                <div className="w-full  flex flex-wrap pl-5 py-12 pr-5">
                  <span className="w-full flex mt-2 text-black text-md text-center">
                    รหัสพันธุ์ไม้ *
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className=" w-full flex flex-wrap">
                      <input value={plantCode} onChange={(e)=>{ setPlantCode(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-black text-md text-center">
                    ชื่อพันธุ์ไม้ *
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input value={plantName.description} onChange={(e)=>{ setPlantName({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button onClick={(e)=>{showFilterSearch(0)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-black text-md text-center">
                    ชื่อคู่ค้า *
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input disabled value={customer.description} onChange={(e)=>{ setCustomer({id: "", description:e.target.value})}}  className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button onClick={(e)=>{showFilterSearch(1)}}   className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>

                  <span className="w-full flex mt-2 text-black text-md text-center">
                    สายพันธุ์หลัก *
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className="flex-grow flex-wrap">
                      <input disabled value={plantMainFamily.description} onChange={(e)=>{ setPlantMainFamily({id: "", description:e.target.value})}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                    <div className="flex-none flex flex-wrap pl-3">
                      <button onClick={(e)=>{showFilterSearch(2)}}  className="bg-green-600 px-3 py-2 rounded-md"><img src={iconSearch} alt=""/></button>
                    </div>
                  </div>


                  <span className="w-full flex mt-2 text-black text-md text-center">
                    วันที่สร้าง *
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className=" w-full flex flex-wrap">
                      {/* <input value={createDate} onChange={(e)=>{ setCreateDate(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="date"  /> */}
                      <DatePicker format="dd/MM/y"  disabled={'disabled'} value={ toDate(createDate) } onChange={ (value)=>{ setCreateDate(toStringDate(value)) } } className='w-full border rounded-md px-1 py-2 content-en' />
                    </div>
                  </div>

                  {/* <span className="w-full flex mt-2 text-black text-md text-center">
                    จำนวนที่สั่ง
                  </span>
                  <div className=" w-full flex flex-wrap mt-1">
                    <div className=" w-full flex flex-wrap">
                      <input value={plantNumberOrder} onChange={(e)=>{ setPlantNumberOrder(e.target.value) }} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />
                    </div>
                  </div> */}

                  <div className=" w-full flex flex-wrap mt-3 justify-end">
                    <button onClick={save} className="ml-3 border px-3 py-2 text-white border-green-500 rounded-lg bg-green-500 font-bold" >บันทึก</button>
                  </div>
                </div>
              </div>
            </div>



          </div>  
        </div>  

      </div>
  )
}

export default ReceiptEditPage;